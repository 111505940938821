import {Box, Modal} from '@mui/material';
import {forwardRef, useImperativeHandle, useRef, useState} from 'react';

import styles from '../../Admin.module.css';
import {ModalContainer} from '../../components/ModalContainer/ModalContainer';
import {ModalHeader} from '../../components/ModalHeader/ModalHeader';
import {SingleCreation} from './SingleCreation';

// eslint-disable-next-line react/display-name
export const AddOrganization = forwardRef((props, ref) => {
  const setOrganizations = useRef(null);
  const [hideModal, setHideModal] = useState(false);
  const [openState, setOpenState] = useState(false);

  useImperativeHandle(
    ref,
    () => ({
      open: async () => {
        setOpenState(true);

        return new Promise((resolve) => {
          setOrganizations.current = (organizations) => {
            // organization obj or undefined
            resolve(organizations);
          };
        });
      }
    }),
    []
  );

  const handleClose = () => {
    setOpenState(false);
    if (setOrganizations.current) {
      setOrganizations.current(undefined);
    }
  };

  const onHideModal = () => {
    setHideModal(true);
  };

  const onShowModal = () => {
    setHideModal(false);
  };

  return (
    <Modal style={{opacity: hideModal ? 0 : 1}} open={openState} onClose={handleClose}>
      <ModalContainer className={styles.modalContent} flexDirection="column">
        <ModalHeader onClose={handleClose} title="Add Organization" />
        <Box className={styles.details}>
          <SingleCreation
            onClose={handleClose}
            onOrganizations={(organizations) => {
              if (setOrganizations.current) {
                setOpenState(false);
                setOrganizations.current(organizations);
              }
            }}
            onHideModal={onHideModal}
            onShowModal={onShowModal}
          />
        </Box>
      </ModalContainer>
    </Modal>
  );
});
