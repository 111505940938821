import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {ReactComponent as ChevronIcon} from 'assets/icons/icon_chevron_right.svg';
import cn from 'classnames';
import React from 'react';

import styles from './MethodologySection.module.css';

export const MethodologySection = ({title, description, expanded, onChange}) => {
  const theme = useTheme();

  return (
    <Accordion style={{color: theme.palette.white.main}} className={styles.accordion} expanded={expanded} onChange={onChange}>
      <AccordionSummary className={styles.summary}>
        <Typography className={styles.title}>{title}</Typography>
        <ChevronIcon
          className={cn(styles.summaryIcon, expanded && styles.summaryIconExpanded)}
          style={{fill: theme.palette.white.main}}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Box display="flex" flexDirection="column" className={styles.descriptionContainer}>
          {description.map((DescriptionItem, index) => (
            <React.Fragment key={index}>
              {typeof DescriptionItem === 'string' ? (
                <Typography className={styles.description}>{DescriptionItem}</Typography>
              ) : (
                <DescriptionItem />
              )}
            </React.Fragment>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};
