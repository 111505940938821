import {apiClient} from 'api/http';

function profile() {
  return apiClient.get('/users/profile/');
}

function adminUsers() {
  return apiClient.get('/admin/users');
}

function createUser(user) {
  return apiClient.post('/admin/users/', {
    ...user,
    is_superuser: user.is_admin
  });
}

function bulkUserCreation(users) {
  return apiClient.post('/admin/users/bulk/', users);
}

function removeUser(userId) {
  return apiClient.delete(`/admin/users/${userId}/`);
}

function updateUser(userData) {
  return apiClient.put(`/users/profile/${userData.id}/`, {
    email: userData.email,
    first_name: userData.first_name,
    last_name: userData.last_name
  });
}

function resetPassword(email) {
  return apiClient.post('/password/reset/', {email});
}

function resendSignUpEmail(userId) {
  return apiClient.post(`/admin/users/${userId}/resend_signup_email/`);
}

function checkAgreement() {
  return apiClient.get('/users/accept-agreement/');
}

function acceptAgreement() {
  return apiClient.post('/users/accept-agreement/');
}

function trackLogin() {
  return apiClient.post('/users/track_login/');
}

export const usersService = {
  profile,
  adminUsers,
  createUser,
  bulkUserCreation,
  removeUser,
  updateUser,
  resetPassword,
  resendSignUpEmail,
  checkAgreement,
  acceptAgreement,
  trackLogin
};
