import {yupResolver} from '@hookform/resolvers/yup';
import {Box, FormControlLabel, Grid, Radio, RadioGroup} from '@mui/material';
import {CustomChipSelect} from 'components/CustomChipSelect/CustomChipSelect';
import {CustomInput} from 'components/CustomInput/CustomInput';
import {ErrorMessage} from 'components/Messages/ErrorMessage';
import {useImperativeRef} from 'hooks/useImperativeRef';
import {useEffect, useMemo} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useAdminStore} from 'stores/hooks/useAdminStore';
import * as yup from 'yup';

import styles from '../../../Admin.module.css';
import {Footer} from '../../../components/Footer/Footer';
import {SubmitBtn} from '../../../components/SubmitBtn/SubmitBtn';
import {ConfirmUserDetails} from '../../../Users/AddUser/ConfirmUserDetails/ConfirmUserDetails';
import inviteUserStyles from './InviteUser.module.css';

const scheme = yup.object({
  first_name: yup.string().required(),
  last_name: yup.string().required(),
  email: yup.string().email().required(),
  organization: yup.string().required(),
  is_staff: yup.boolean()
});

export const InviteSingleUser = ({onUsers, organization, onHideModal, onShowModal}) => {
  const {users, organizations} = useAdminStore();
  const alreadyCreatedUsers = users.data || [];
  const [confirmUserDetails, setConfirmUserDetails] = useImperativeRef(
    null,
    {
      beforeCall: onHideModal,
      afterCall: onShowModal
    },
    ['open']
  );
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
    setError
  } = useForm({
    resolver: yupResolver(scheme),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      organization: organization.name,
      is_staff: false
    }
  });
  const currentEmail = watch('email');

  const emailAlreadyRegistered = (email) => {
    if (errors.email?.type !== undefined || errors.email?.type !== 'custom') {
      return errors.email;
    }

    const user = alreadyCreatedUsers.find((user) => user.email === email);

    const errorMessage = user ? {type: 'custom', message: 'Current email already registered.'} : undefined;

    setError('email', errorMessage);

    return errorMessage;
  };

  useEffect(() => {
    emailAlreadyRegistered(currentEmail);
  }, [currentEmail]);

  const onSubmit = async (user) => {
    const emailCheckResult = emailAlreadyRegistered(user.email);
    if (emailCheckResult) {
      return;
    }

    user.organization_id = organization.id;
    const result = await confirmUserDetails.current.open([user]);

    if (result?.confirmed) {
      if (result?.isError) {
        const errors = result?.responseData;
        errors.forEach((error) => {
          Object.entries(error).forEach(([field, messages]) => {
            setError(field, {type: 'serverError', message: messages[0]});
          });
        });
        return;
      }

      onUsers(result?.updateUsers);
      users.execute();
    }
  };

  const organizationsOptions = useMemo(() => {
    const result = organizations.data || [];

    return result.map((organization) => organization.name);
  }, [organizations.data]);

  return (
    <Box className={styles.singleCreation}>
      <ConfirmUserDetails ref={setConfirmUserDetails} />
      <Grid container>
        <Grid item xs={12} className={styles.isAdminContainer}>
          <Controller
            control={control}
            rules={{required: true}}
            render={({field: {...props}}) => (
              <RadioGroup
                row
                defaultValue="user"
                value={props.value ? 'admin' : 'user'}
                onChange={(e, value) => {
                  props.onChange({target: {value: value === 'admin'}});
                }}
              >
                <FormControlLabel
                  value="user"
                  control={<Radio size="small" color="red" />}
                  label="User"
                  className={styles.radioLabel}
                />
                <FormControlLabel
                  value="admin"
                  control={<Radio size="small" color="red" />}
                  label="Administrator"
                  className={styles.radioLabel}
                />
              </RadioGroup>
            )}
            name="is_staff"
          />
          {!!errors.is_staff && <ErrorMessage message={errors.is_staff.message} />}
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={{required: true}}
              render={({field: {...props}}) => (
                <CustomInput label="First Name" required={true} placeholder="First Name" {...props} />
              )}
              name="first_name"
            />
            {!!errors.first_name && <ErrorMessage message={errors.first_name.message} />}
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={{required: true}}
              render={({field: {...props}}) => (
                <CustomInput label="Last Name" required={true} placeholder="Last Name" {...props} />
              )}
              name="last_name"
            />
            {!!errors.last_name && <ErrorMessage message={errors.last_name.message} />}
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={{required: true}}
              render={({field: {...props}}) => <CustomInput label="Email" required={true} placeholder="Email" {...props} />}
              name="email"
            />
            {!!errors.email && <ErrorMessage message={errors.email.message} />}
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={{required: true}}
              render={({field: {...props}}) => (
                <CustomChipSelect
                  onChange={(options) => {
                    props.onChange({target: {value: options[0]}});
                  }}
                  label="Organizations"
                  options={organizationsOptions}
                  maxOptions={1}
                  disabled={true}
                  value={props.value ? [props.value] : []}
                  chipClassName={inviteUserStyles.organizationChip}
                />
              )}
              name="organization"
            />
            {!!errors.organization && <ErrorMessage message={errors.organization.message} />}
          </Grid>
        </Grid>
      </Grid>
      <Footer display="flex" justifyContent="flex-end">
        <SubmitBtn
          onClick={async () => {
            await handleSubmit(onSubmit)();
            emailAlreadyRegistered(currentEmail);
          }}
          label="Upload"
        />
      </Footer>
    </Box>
  );
};
