import {useAuth0} from '@auth0/auth0-react';
import LoadingButton from '@mui/lab/LoadingButton';
import {Box, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {logout} from 'api/auth';
import {usersService} from 'api/services/users';
import {Loader} from 'components/Loader/Loader';
import {useApi} from 'hooks/useApi';
import {enqueueSnackbar} from 'notistack';
import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router';
import {useProfileStore} from 'stores/hooks/useProfileStore';

import styles from './Eula.module.css';

export const Eula = () => {
  const {logout: authLogout} = useAuth0();
  const [profile, userProfileStoreDispatch] = useProfileStore();
  const navigate = useNavigate();
  const documentContainer = useRef(null);
  const [documentLoading, setDocumentLoading] = useState(true);
  const agreementApi = useApi({
    service: usersService.acceptAgreement,
    immediate: false
  });
  const theme = useTheme();

  useEffect(() => {
    const documentBlock = documentContainer.current;
    if (!documentBlock) {
      return;
    }

    fetch('/user_license_agreement.html').then(async (response) => {
      documentBlock.innerHTML = await response.text();
      setDocumentLoading(false);
    });
  }, [documentContainer]);

  const acceptAgreement = async () => {
    const result = await agreementApi.execute();
    if (result instanceof Error) {
      enqueueSnackbar('Something went wrong, try again.', {
        variant: 'error',
        preventDuplicate: true,
        key: 'accept-agreement',
        autoHideDuration: 2500
      });
    } else {
      userProfileStoreDispatch({
        type: 'updateUser',
        user: {
          ...profile.user,
          accepted_agreement: true
        }
      });
      navigate('/', {replace: true, state: {scrollToTop: true}});
    }
  };

  const cancelAgreement = async () => {
    logout(false);
    await authLogout({logoutParams: {returnTo: `${window.location.origin}/login`}});
  };

  return (
    <Box>
      <Loader absolute={true} loading={documentLoading} />
      <Box className={styles.header} style={{backgroundColor: theme.palette.secondary.main}}>
        <Typography className={styles.headerTitle} style={{color: theme.palette.white.main}}>
          TRITON End User License Agreement
        </Typography>
      </Box>
      <Box className={styles.container} maxWidth={1200}>
        <Box ref={documentContainer} />
        <Box display="flex" alignItems="center" justifyContent="flex-end" className={styles.containerFooter}>
          <LoadingButton
            variant="outlined"
            type="submit"
            onClick={cancelAgreement}
            disabled={!!profile.user?.accepted_agreement}
            loading={profile.loading}
          >
            Decline
          </LoadingButton>
          <LoadingButton
            onClick={acceptAgreement}
            variant="contained"
            loadingPosition="center"
            color="red"
            loading={agreementApi.loading || profile.loading}
            style={{color: theme.palette.white.main}}
            disabled={!!profile.user?.accepted_agreement}
          >
            Accept
          </LoadingButton>
        </Box>
      </Box>
    </Box>
  );
};
