export const openSupportWindow = ({
  url = 'https://portal.usepylon.com/c4ads/forms/triton-submit-feedback',
  padding = 64,
  maxWidth = 768
}) => {
  const height = window.innerHeight - padding * 2;
  const width = window.innerWidth < maxWidth ? window.innerWidth : maxWidth;

  const top = padding + (window.outerHeight - window.innerHeight);
  const left = (window.innerWidth - width) / 2;

  window.open(
    url,
    'Triton Support',
    `
    top=${top},left=${left},width=${width},height=${height},
    resizable=yes,scrollbars=yes,status=no,location=no,toolbar=no,menubar=no,popup=yes
    `
  );
};
