import {createContext, useState} from 'react';

const initialState = {mainNode: null};

export const DetailsPanelContext = createContext(initialState);

export const DetailsPanelWrapper = ({children}) => {
  const [mainNode, setMainNode] = useState(null);

  return <DetailsPanelContext.Provider value={{mainNode, setMainNode}}>{children}</DetailsPanelContext.Provider>;
};
