import {Box, Typography} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {ReactComponent as CloseIcon} from 'assets/icons/icon_warning_close.svg';
import {ReactComponent as InfoIcon} from 'assets/icons/icon_warning_info.svg';

import styles from './UserNotFound.module.css';

export const UserNotFound = ({onClose}) => {
  return (
    <Box display="flex" flexDirection="column" className={styles.errorBox}>
      <Box display="flex" alignItems="center" className={styles.errorContainer}>
        <InfoIcon className={styles.errorIcon} />
        <Box display="flex" flexDirection="column">
          <Typography className={styles.errorText}>Your account has not been authorized for Triton.</Typography>
          <Typography className={styles.errorText}>
            Please contact
            {' '}
            <a href="mailto:contact@triton.fish" className={styles.link}>
              contact@triton.fish
            </a>
          </Typography>
        </Box>
        <IconButton variant="contained" color="secondary" onClick={onClose}>
          <CloseIcon className={styles.closeIcon} />
        </IconButton>
      </Box>
    </Box>
  );
};
