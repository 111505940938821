import styled from '@emotion/styled';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import cn from 'classnames';
import {useMemo} from 'react';
import {useLocation} from 'react-router';
import {NavLink} from 'react-router-dom';
import {useProfileStore} from 'stores/hooks/useProfileStore';
import {openSupportWindow} from 'utils/openSupportWindow';

import {DefaultAccountButton, UserAccountButton} from '../AccountButton/AccountButton';
import styles from './NavItems.module.css';

const StyledNavLink = styled(NavLink)(({theme}) => ({color: theme.palette.white.main}));

export const NavItems = ({itemOnClick}) => {
  const [profile] = useProfileStore();
  const theme = useTheme();
  const isSmallViewport = useMediaQuery(theme.breakpoints.between('xs', 'lg'));
  const {pathname} = useLocation();

  const links = useMemo(() => {
    // eslint-disable-next-line max-len
    return [
      {href: '/', label: 'Dashboard', onClick: itemOnClick},
      {href: '/search', label: 'Search', onClick: itemOnClick},
      {href: '/methodology', label: 'Methodology', onClick: itemOnClick},
      {
        href: '#support',
        label: 'Support',
        onClick: () => {
          openSupportWindow({});
          itemOnClick?.();
        }
      }
    ];
  }, [profile.user]);

  return (
    <>
      {!!profile.user && (
        <>
          {links.map((link) => (
            <StyledNavLink
              key={link.href}
              to={link.href}
              onClick={link.onClick}
              className={cn(styles.navLink, link.href === pathname && styles.activeNavLink)}
            >
              {link.label}
            </StyledNavLink>
          ))}
        </>
      )}
      <Box sx={{marginTop: isSmallViewport ? '0.5rem' : '0px'}}>
        {!profile.user && !profile.loading && <DefaultAccountButton />}
        {!profile.user && !!profile.loading && <CircularProgress className={styles.accountLoading} color="inProgress" />}
        {!!profile.user && <UserAccountButton itemOnClick={itemOnClick} />}
      </Box>
    </>
  );
};
