import {Box, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {ReactComponent as HouseIcon} from 'assets/icons/icon_house.svg';
import cn from 'classnames';
import React, {useMemo} from 'react';

import styles from './MemberChain.module.css';

export const MemberChain = ({chain, onMember, tritonId, white}) => {
  const theme = useTheme();

  const [root, tail] = useMemo(() => {
    const [root, ...tail] = chain;

    const result = [];
    for (let i = 0; i < tail.length; i += 2) {
      result.push([tail[i], tail[i + 1]]);
    }

    return [root, result];
  }, [chain]);

  return (
    <Box display="flex" alignItems="center" className={styles.chain}>
      <Box
        display="flex"
        alignItems="center"
        className={cn(styles.titleBlock, styles.member)}
        onClick={() => {
          if (root.node?.entity?.triton_id !== tritonId) {
            onMember(root.node.entity);
          }
        }}
      >
        <HouseIcon style={{fill: white ? theme.palette.white.main : theme.palette.secondary.main}} />
        <Typography title={root.node?.entity?.label || ''} className={styles.title} color={white ? 'white' : 'secondary'}>
          {root.node?.entity?.label || ''}
        </Typography>
      </Box>
      <span style={{backgroundColor: white ? theme.palette.white.main : theme.palette.gray.gray100}} className={styles.divider} />
      {tail.map(([edgeItem, nodeItem], index) => {
        return (
          <React.Fragment key={index}>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              className={styles.chainMemberWrapper}
              onClick={() => {
                if (nodeItem.node?.entity?.triton_id !== tritonId) {
                  onMember(nodeItem.node.entity);
                }
              }}
            >
              <Typography title={edgeItem.edge?.relation_type} className={styles.chainEdge} color={white ? 'white' : 'gray'}>
                {edgeItem.edge?.relation_type}
              </Typography>
              <Box display="flex" alignItems="center" className={cn(styles.titleBlock, styles.member)}>
                <Typography
                  title={nodeItem.node?.entity?.label || ''}
                  className={styles.title}
                  color={white ? 'white' : 'secondary'}
                >
                  {nodeItem.node?.entity?.label || ''}
                </Typography>
              </Box>
            </Box>
            {index !== tail.length - 1 && (
              <span
                style={{backgroundColor: white ? theme.palette.white.main : theme.palette.gray.gray100}}
                className={styles.divider}
              />
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
};
