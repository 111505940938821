import CloseIcon from '@mui/icons-material/Close';
import {Box, IconButton, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';

import styles from './Files.module.css';

export const Files = ({files, onRemove}) => {
  const theme = useTheme();

  return (
    <Box className={styles.files} display="flex" flexDirection="column">
      {files.map((filename) => (
        <Box
          key={filename}
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          className={styles.fileContainer}
          style={{backgroundColor: theme.palette.secondary.alpha10}}
        >
          <Typography color="secondary" className={styles.filename}>
            {filename}
          </Typography>
          <IconButton
            onClick={() => {
              onRemove(filename);
            }}
          >
            <CloseIcon className={styles.fileDeleteIcon} color="secondary" />
          </IconButton>
        </Box>
      ))}
    </Box>
  );
};
