import {entitiesService} from 'api/services/entities';
import cn from 'classnames';
import {AccessDenied} from 'components/AccessDenied/AccessDenied';
import {SearchResultsTable} from 'components/SearchResultsTable/SearchResultsTable';
import {useApi} from 'hooks/useApi';
import {useSubscriber} from 'hooks/useSubscriber';
import {useEffect, useMemo, useState} from 'react';
import {useNavigate} from 'react-router';

import homePageStyles from '../../HomePage.module.css';

export const Favorites = (props) => {
  const {onClickCell, broadcaster, visible} = props;
  const navigate = useNavigate();
  const subscriber = useSubscriber(broadcaster, 'favorites');
  const favorite = useApi({
    service: entitiesService.favorite,
    immediate: false
  });
  const unfavorite = useApi({
    service: entitiesService.unfavorite,
    immediate: false
  });
  const favorites = useApi({service: entitiesService.favorites});
  const [pagination, setPagination] = useState({
    page: 0,
    count: 0,
    pageSize: 10
  });

  useEffect(() => {
    if (!favorites.loading) {
      setPagination((prev) => ({...prev, count: favorites?.data?.length || 0}));
    }
  }, [favorites.loading]);

  useEffect(() => {
    if (!subscriber) {
      return;
    }

    return subscriber.on('data', (data) => {
      if (data.type === 'isFavorite') {
        favorites.execute();
      }
    });
  }, [subscriber]);

  const onUpdateFavorite = async (is_favorite, triton_id) => {
    const payload = {triton_id};
    const result = is_favorite ? await favorite.execute(payload) : await unfavorite.execute(payload);
    if (result instanceof Error) {
      return;
    }

    if (broadcaster) {
      broadcaster.sendData({
        type: 'isFavorite',
        isFavorite: is_favorite,
        tritonId: triton_id
      });
    }
  };

  const onPage = (page) => {
    setPagination({...pagination, page});
  };

  const onPageSize = (pageSize) => {
    setPagination({...pagination, pageSize});
  };

  const viewNetworksHandleClick = (tritonId) => {
    navigate(`/networks?triton_id=${tritonId}`);
  };

  const rows = useMemo(() => {
    if (!favorites?.data) {
      return [];
    }
    return favorites.data.map(({entity}) => ({...entity, is_favorite: true}));
  }, [favorites.data, pagination]);

  return (
    <div className={cn(homePageStyles.resultsContainer, !visible && homePageStyles.hiddenTableStyles)}>
      {favorites.status === 403 && (
        <AccessDenied
          className={homePageStyles.accessDenied}
          absolute={true}
          title="You do not have permission to use the Favorites table."
        />
      )}
      <SearchResultsTable
        rows={rows}
        loading={favorites.loading}
        onClickCell={onClickCell}
        viewNetworksHandleClick={viewNetworksHandleClick}
        onFavorite={onUpdateFavorite}
        pagination={pagination}
        onPage={onPage}
        onPageSize={onPageSize}
        dataGridProps={{
          paginationMode: 'client',
          sortingMode: 'client'
        }}
      />
    </div>
  );
};
