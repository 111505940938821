import SortByAlphaIcon from '@mui/icons-material/SortByAlpha';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress/CircularProgress';
import {useTheme} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {ReactComponent as SearchIcon} from 'assets/icons/icon_search.svg';
import cn from 'classnames';
import {FilterCheckbox} from 'components/EntityFilterCheckbox/EntityFilterCheckbox';
import {sortBy} from 'lodash';
import {useCallback, useEffect, useMemo, useState} from 'react';

import styles from './CustomAutocomplete.module.css';

export const CustomAutocomplete = ({
  placeholder,
  options,
  loading,
  value,
  onChange,
  onSearchInput,
  className,
  itemLabelClassName,
  emptyText
}) => {
  // options -> {key, label}[]
  const theme = useTheme();
  const [sort, setSort] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState(value || []);
  const [search, setSearch] = useState('');

  useEffect(() => {
    setCheckedOptions(value || []);
  }, [value]);

  const onCheck = (key, checked) => {
    const cloned = checkedOptions.slice();

    if (checked) {
      if (cloned.indexOf(key) === -1) {
        cloned.push(key);
      }
    } else {
      const index = cloned.indexOf(key);
      if (index !== -1) {
        cloned.splice(index, 1);
      }
    }

    setCheckedOptions(cloned);
    onChange(cloned);
  };

  const onSearch = (e) => {
    const value = e.target.value;
    onSearchInput && onSearchInput(value);
    setSearch(value);
  };

  const filterFunction = useCallback((searchValue) => {
    const query = searchValue.toLowerCase();
    return ({label}) => label.toLowerCase().indexOf(query) !== -1;
  }, []);

  const toggleSort = () => {
    setSort((prev) => !prev);
  };

  const preparedOptions = useMemo(
    () => sortBy(options, [sort ? ['label'] : []]).filter(filterFunction(search)),
    [options, sort, search]
  );

  return (
    <Box className={cn(className, styles.box)}>
      <TextField
        placeholder={placeholder}
        InputProps={{
          startAdornment: <SearchIcon className={styles.searchIcon} style={{fill: theme.palette.secondary.main}} />,
          endAdornment: (
            <SortByAlphaIcon
              onClick={toggleSort}
              className={styles.sortIcon}
              style={{fill: sort ? theme.palette.secondary.main : theme.palette.gray.gray100}}
            />
          )
        }}
        sx={{color: theme.palette.gray.gray100}}
        variant="outlined"
        onChange={onSearch}
        className={styles.textField}
      />
      <Box className={styles.optionsContainer}>
        {!loading && preparedOptions.length === 0 && (
          <Typography color="secondary" className={styles.emptyText}>
            {emptyText}
          </Typography>
        )}
        {!!loading && (
          <Box display="flex" alignItems="center" justifyContent="center" sx={{gap: '8px'}}>
            <Typography color="secondary" className={styles.emptyText}>
              Loading
            </Typography>
            <CircularProgress size={18} color="inProgress" />
          </Box>
        )}
        {preparedOptions.map(({key, label, renderLabel}) => (
          <FilterCheckbox
            className={cn(styles.checkbox, itemLabelClassName)}
            key={key}
            labelSx={{width: '100%'}}
            label={renderLabel ? renderLabel() : label}
            checked={checkedOptions.includes(key)}
            onChange={(checked) => onCheck(key, checked)}
          />
        ))}
      </Box>
    </Box>
  );
};
