import {Modal} from '@mui/material';
import {organizationServices} from 'api/services/organizations';
import {Loader} from 'components/Loader/Loader';
import {useApi} from 'hooks/useApi';
import {forwardRef, useImperativeHandle, useMemo, useRef, useState} from 'react';

import {Footer} from '../../../components/Footer/Footer';
import {ModalContainer} from '../../../components/ModalContainer/ModalContainer';
import {ModalHeader} from '../../../components/ModalHeader/ModalHeader';
import {SubmitBtn} from '../../../components/SubmitBtn/SubmitBtn';
import {OrganizationPreview} from '../../ConfirmOrganizationDetails/OrganizationPreview';

// eslint-disable-next-line react/display-name,no-unused-vars
export const ConfirmEditedOrganizationDetails = forwardRef((props, ref) => {
  const confirm = useRef(null);
  const [openState, setOpenState] = useState(false);
  const [organization, setOrganization] = useState();
  const [loading, setLoading] = useState(false);
  const editOrganization = useApi({
    service: organizationServices.updateOrganization,
    immediate: false
  });

  useImperativeHandle(
    ref,
    () => ({
      open: async (organization) => {
        setOrganization(organization);
        setOpenState(true);

        return new Promise((resolve) => {
          confirm.current = (result) => {
            resolve(result);
          };
        });
      }
    }),
    []
  );

  const handleClose = () => {
    setOpenState(false);
    setOrganization();

    if (confirm.current) {
      confirm.current({confirmed: false});
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    const result = await editOrganization.execute({...organization});
    setLoading(false);

    if (result instanceof Error) {
      return;
    }

    if (confirm.current) {
      confirm.current({confirmed: true, organization: result});
    }
    setOpenState(false);
    setOrganization();
  };

  const organizationViewComponent = useMemo(() => {
    if (organization) {
      return <OrganizationPreview organization={organization} />;
    }
  }, [organization]);

  return (
    <Modal open={openState} onClose={handleClose}>
      <ModalContainer flexDirection="column">
        <Loader absolute={true} loading={loading} />
        <ModalHeader onClose={handleClose} title="Confirm Organization Details" />
        {organizationViewComponent}
        <Footer width="100%" display="flex" justifyContent="flex-end">
          <SubmitBtn label="Submit" onClick={onSubmit} />
        </Footer>
      </ModalContainer>
    </Modal>
  );
});
