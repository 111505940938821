export const CustomFileDragAndDrop = ({formats, onFiles, onDragOver, onDragEnd, onDragLeave, className, title}) => {
  const onDropFiles = (e) => {
    e.preventDefault();

    let files = Array.from(e.dataTransfer.files || []);

    files = files.filter((file) => {
      const fileNameParts = file.name.split('.');

      const fileFormat = fileNameParts[fileNameParts.length - 1];
      return formats.includes(fileFormat);
    });
    if (files.length > 0) {
      onFiles(files);
    }
  };

  const onUploadFiles = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.multiple = true;
    input.accept = formats.map((f) => `.${f}`).join(', ');

    const handler = () => {
      onFiles(Array.from(input.files));

      input.removeEventListener('change', handler);
      input.remove();
    };

    input.addEventListener('change', handler);
    input.click();
  };

  return (
    <div
      id="drop_zone"
      className={className}
      onClick={onUploadFiles}
      onDrop={onDropFiles}
      onDragLeave={onDragLeave}
      onDragOver={(e) => {
        e.preventDefault();
        onDragOver();
      }}
      onDragEnd={onDragEnd}
    >
      {title}
    </div>
  );
};
