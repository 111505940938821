import {useAuth0} from '@auth0/auth0-react';
import LoadingButton from '@mui/lab/LoadingButton';
import {Box, Link, Stack} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {Loader} from 'components/Loader/Loader';
import {USER_NOT_FOUND} from 'constants/sessionStorage';
import {ABOUT_URL, LICENSE_URL, REQUEST_ACCESS_URL} from 'constants/urls';
import {closeSnackbar, enqueueSnackbar} from 'notistack';
import {useEffect, useState} from 'react';
import {useLocation} from 'react-router';

import {UserNotFound} from '../UserNotFound/UserNotFound';
import styles from './LoginForm.module.css';

export const LoginForm = () => {
  const {loginWithRedirect} = useAuth0();
  const theme = useTheme();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (sessionStorage.getItem(USER_NOT_FOUND) === 'true') {
      enqueueSnackbar(
        <UserNotFound
          onClose={() => {
            closeSnackbar('sign-in-error-user-not-found');
          }}
        />,
        {
          preventDuplicate: true,
          autoHideDuration: 30000,
          key: 'sign-in-error-user-not-found',
          variant: 'error',
          anchorOrigin: {horizontal: 'right', vertical: 'bottom'}
        }
      );
      sessionStorage.removeItem(USER_NOT_FOUND);
    }
  }, [location]);

  const onLoad = () => {
    setLoading(true);
    loginWithRedirect();
  };

  return (
    <Stack className={styles.loginForm}>
      <link rel="stylesheet" href="/LoginFormGlobal.css" />
      <Loader loading={loading} absolute={true} />
      <LoadingButton
        fullWidth
        onClick={onLoad}
        variant="contained"
        subvariant="fat"
        loadingPosition="center"
        color="red"
        loading={loading}
      >
        <span className={styles.loginLabel} style={{color: theme.palette.white.main}}>
          Log in
        </span>
      </LoadingButton>
      <Box display="flex" alignItems="center" className={styles.linksContainer}>
        <Link
          href={REQUEST_ACCESS_URL}
          variant="body1"
          target="_blank"
          rel="noopener"
          color="black.main"
          underline="hover"
          className={styles.link}
        >
          Request Access
        </Link>
        <Link
          href={LICENSE_URL}
          variant="body1"
          target="_blank"
          rel="noopener"
          color="black.main"
          underline="hover"
          className={styles.link}
        >
          EULA
        </Link>
        <Link
          href={ABOUT_URL}
          variant="body1"
          target="_blank"
          rel="noopener"
          color="black.main"
          underline="hover"
          className={styles.link}
        >
          About
        </Link>
      </Box>
    </Stack>
  );
};
