import Button from '@mui/material/Button';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {ReactComponent as FilterIcon} from 'assets/icons/icon_filter_solid.svg';
import {ReactComponent as SearchIcon} from 'assets/icons/icon_search_solid.svg';
import cn from 'classnames';

import styles from '../QuickSearch.module.css';

export const QuickSearchInputEndAdornment = ({onClickFilters, onClear, onClickSearch}) => {
  const theme = useTheme();

  return (
    <div className={styles.endAdornment}>
      <div
        className={cn(styles.filterBtn, styles.desktopTextFieldBtn)}
        style={{background: theme.palette.grey.A100}}
        onClick={onClickFilters}
      >
        <Typography className={styles.filterBtnText} variant="body1" color="secondary.main">
          Filter Results
        </Typography>
        <FilterIcon className={styles.filterIcon} />
      </div>
      <Typography
        onClick={onClear}
        className={cn(styles.clearBtn, styles.desktopTextFieldBtn)}
        sx={{color: theme.palette.steel.steel400}}
        variant="body1"
      >
        Clear
      </Typography>
      <Button
        className={styles.searchBtn}
        fullWidth
        variant="contained"
        type="submit"
        onClick={onClickSearch}
        sx={{background: theme.palette.red.main}}
      >
        <SearchIcon className={styles.searchIcon} />
      </Button>
    </div>
  );
};
