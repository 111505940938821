import {Box} from '@mui/material';
import cn from 'classnames';

import styles from './Footer.module.css';

export const Footer = ({children, className, ...props}) => (
  <Box className={cn(styles.footer, className)} {...props}>
    {children}
  </Box>
);
