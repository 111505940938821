import {Modal} from '@mui/material';
import {useEffect, useState} from 'react';

import {ModalContainer} from '../ModalContainer/ModalContainer';
import {ModalHeader} from '../ModalHeader/ModalHeader';
import {FileObj} from './FileObj';
import styles from './FilesErrors.module.css';

export const FilesErrors = ({errors, open, handleClose}) => {
  const [openState, setOpenState] = useState(open);
  /*
   errors -> { fileName: [{index: number, errors: [ {currentValue, path, pathErrorMessages} ]}] }
  */
  useEffect(() => {
    setOpenState(open);
  }, [open]);

  const onClose = () => {
    setOpenState(false);
    handleClose();
  };

  return (
    <Modal open={openState} onClose={onClose}>
      <ModalContainer className={styles.modalContent} flexDirection="column">
        <ModalHeader onClose={onClose} hideLine={true} />
        {
          // eslint-disable-next-line max-len
          !!errors &&
            Object.entries(errors).map(([fileName, errors]) => <FileObj key={fileName} fileName={fileName} errors={errors} />)
        }
      </ModalContainer>
    </Modal>
  );
};
