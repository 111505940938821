import {useEffect, useRef} from 'react';

import {useNetworkGraphContext} from '../../../../../stores/hooks/useNetworkGraphContext';
import {NodeIcons} from '../cytoscapeStyles';
import {makePopper} from '../EdgePopover/EdgePopover';
import {closeTippy, removeChildNodes} from '../networksGraphTools';

export const EdgesLayer = ({nodes}) => {
  const {cyto} = useNetworkGraphContext();
  const tippyRef = useRef([]);

  const getNodeIcon = (id) => {
    let type = nodes?.filter((x) => x.data.id === id)[0]?.data?.entityType;

    return NodeIcons[type].whiteImage;
  };

  const bindListeners = () => {
    if (!cyto) {
      return;
    }

    const handleCloseTippy = closeTippy(tippyRef);

    let timer = 0;
    let prevent = false;

    cyto.edges().unbind('mouseover');
    cyto.bind('mouseover', 'edge.i', (event) => {
      makePopper(cyto, event.target, getNodeIcon, handleCloseTippy);
    });

    cyto.on('dblclick', 'edge.i', (event) => {
      if (event.originalEvent.target.tagName !== 'CANVAS') {
        return;
      }
      clearTimeout(timer);
      prevent = true;

      const prev = [...tippyRef.current].find((badge) => badge._private?.data?.id === event.target?._private.data.id);

      if (prev) {
        handleCloseTippy(event.target);
      } else if (event.target.badge && !prev) {
        tippyRef.current = [...tippyRef.current, event.target];
      } else if (!event.target.badge) {
        const badge = makePopper(event.target);
        tippyRef.current = [...tippyRef.current, badge];
      }
    });

    cyto.bind('mouseout', 'edge', (event) => {
      const prev = [...tippyRef.current].find((badge) => badge._private?.data?.id === event.target?._private.data.id);

      if (!prev) {
        removeChildNodes(event.target, 'canvas-popup-element');

        const badge = event.target.badge;
        if (badge) {
          badge.destroy();
          badge.state.elements.popper.remove();
        }
      }
    });
  };

  useEffect(() => bindListeners(), [cyto]);

  return null;
};
