import {Box, Modal, Typography} from '@mui/material';
import {usersService} from 'api/services/users';
import {Loader} from 'components/Loader/Loader';
import {useApi} from 'hooks/useApi';
import {forwardRef, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {useAdminStore} from 'stores/hooks/useAdminStore';

import {CancelBtn} from '../../components/CancelBtn/CancelBtn';
import {ModalContainer} from '../../components/ModalContainer/ModalContainer';
import {ModalHeader} from '../../components/ModalHeader/ModalHeader';
import {SubmitBtn} from '../../components/SubmitBtn/SubmitBtn';
import dialogStyles from '../../styles/dialogDefaultStyles.module.css';
import styles from './ResendEmailDialog.module.css';

// eslint-disable-next-line react/display-name
export const ResendEmailDialog = forwardRef((props, ref) => {
  const {users} = useAdminStore();
  const resendRef = useRef(null);
  const [openState, setOpenState] = useState(false);
  const [userId, setUserId] = useState();
  const resendEmail = useApi({
    service: usersService.resendSignUpEmail,
    immediate: false
  });

  useImperativeHandle(
    ref,
    () => ({
      open: async (userId) => {
        setUserId(userId);
        setOpenState(true);

        return new Promise((resolve) => {
          resendRef.current = (result) => {
            resolve(result);
            setOpenState(false);
            setUserId();
          };
        });
      }
    }),
    []
  );

  const handleClose = () => {
    setOpenState(false);

    if (resendRef.current) {
      resendRef.current(false);
    }
  };

  const removeUserHandler = async () => {
    const result = await resendEmail.execute(userId);

    if (result instanceof Error) {
      return;
    }

    if (resendRef.current) {
      resendRef.current(true);
    }
  };

  const user = useMemo(() => {
    return users?.data?.find((user) => user.id === userId);
  }, [users, userId]);

  return (
    <Modal open={openState} onClose={handleClose}>
      <ModalContainer flexDirection="column">
        <Loader absolute={true} loading={resendEmail.loading} />
        <ModalHeader className={dialogStyles.header} onClose={handleClose} hideLine={true} absolute={true} />
        <Typography variant="h4" color="secondary" className={dialogStyles.title}>
          Resend Invitation
        </Typography>
        <Typography variant="p" color="secondary" className={dialogStyles.subTitle}>
          Are you sure you want to resend an invitation to
          {' '}
          <Typography variant="p" color="secondary" className={styles.userData}>
            {user?.email}
          </Typography>
          ?
        </Typography>
        <Box display="flex" alignItems="center" justifyContent="flex-start" className={dialogStyles.footer}>
          <SubmitBtn onClick={removeUserHandler} label="Confirm" />
          <CancelBtn label="Cancel" onClick={handleClose} />
        </Box>
      </ModalContainer>
    </Modal>
  );
});
