import IconButton from '@mui/material/IconButton';
import {useTheme} from '@mui/material/styles';
import {ReactComponent as StarIcon} from 'assets/icons/icon_filled_star.svg';
import {ReactComponent as StarBorderIcon} from 'assets/icons/icon_open_star.svg';
import {Toast} from 'components/Toast/Toast';
import {useResizeEvent} from 'hooks/useResizeEvent';
import {useEffect, useRef, useState} from 'react';

import {UnfavoriteConfirmation} from './components/UnfavoriteConfirmation';
import styles from './FavoriteButton.module.css';

export const FavoriteButton = (props) => {
  const {isFavorite, onUpdateFavorite, sx} = props;
  const [toastOpen, setToastOpen] = useState(false);
  const [menuAnchor, setMenuAnchor] = useState();
  const menuRef = useRef(null);
  const resize = useResizeEvent();
  const theme = useTheme();

  const handleUpdateFavorite = (is_favorite) => {
    if (!onUpdateFavorite) {
      return;
    }
    onUpdateFavorite(is_favorite);
  };

  useEffect(() => {
    setMenuAnchor(null);
    setToastOpen(false);
  }, [resize.event]);

  const handleClick = (button) => {
    if (button === 'icon') {
      if (!isFavorite) {
        handleUpdateFavorite(true);
      } else {
        setMenuAnchor(menuRef.current);
      }
    } else if (button === 'confirmation') {
      setToastOpen(true);

      handleUpdateFavorite(false);
      setMenuAnchor(null);
    }
  };

  return (
    <>
      <IconButton
        onClick={(e) => {
          e.stopPropagation();
          handleClick('icon');
        }}
        ref={menuRef}
        sx={[...(Array.isArray(sx) ? sx : [sx])]}
        disableRipple
      >
        {!!isFavorite && <StarIcon className={styles.favoriteButtonIcon} style={{fill: theme.palette.red.red800}} />}
        {!isFavorite && <StarBorderIcon className={styles.favoriteButtonIcon} style={{fill: theme.palette.red.red800}} />}
      </IconButton>
      <UnfavoriteConfirmation menuAnchor={menuAnchor} onClick={handleClick} onClose={() => setMenuAnchor(null)} />
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message="Entity has been removed from your favorites." />
    </>
  );
};
