import {Typography} from '@mui/material';
import {ReactComponent as CountryIcon} from 'assets/icons/icon_country_filter.svg';
import {ReactComponent as EntityTypeIcon} from 'assets/icons/icon_entity_type.svg';
import {ReactComponent as FleetTagsIcon} from 'assets/icons/icon_fleet_tags.svg';
import {ReactComponent as IUUStatusIcon} from 'assets/icons/icon_iuu_status.svg';
import {ReactComponent as SanctionStatusIcon} from 'assets/icons/icon_sanction_status.svg';
import {ReactComponent as SourcesIcon} from 'assets/icons/icon_sources.svg';
import {RFMO_SOURCES} from 'constants/entities';
import {camelToTitle, prepareFilterKeyToLabelAndCapitalizeAllWords} from 'utils/helpers';

import {CountryFilterComponent} from './FilterComponent/CountryFilterComponent/CountryFilterComponent';
import {FilterComponent} from './FilterComponent/FilterComponent';
import styles from './SearchFilters.module.css';

const defaultDecode = (obj) => obj;
const defaultEncode = (obj) => obj;
const defaultIsDisabled = () => false;
const defaultReset = (filterType) => (update, filters) => {
  // eslint-disable-next-line no-use-before-define
  const values = getValues(filters, filterType);

  Object.keys(values).forEach((key) => {
    values[key] = false;
  });

  update(filterType, values);
};

const IsDisabledTooltipContent = () => {
  return (
    <Typography color="white" className={styles.disabledTooltipContent}>
      This filter is only available for
      <Typography color="white" className={styles.disabledTooltipContentSubText}>
        vessel entity types.
      </Typography>
    </Typography>
  );
};

export const getFiltersConfig = (filters) => [
  {
    type: 'typeFilters',
    label: {
      name: 'Entity Type',
      icon: EntityTypeIcon
    },
    placeholder: 'Search entity types',
    options: Object.keys(filters.typeFilters).map((key) => ({
      key,
      label: camelToTitle(key)
    })),
    decode: defaultDecode,
    encode: defaultEncode,
    component: FilterComponent,
    isDisabled: defaultIsDisabled,
    emptyText: '',
    reset: defaultReset('typeFilters')
  },
  {
    type: 'countries',
    label: {
      name: 'Country',
      icon: CountryIcon
    },
    placeholder: 'Search countries',
    options: [],
    decode: defaultDecode,
    encode: defaultEncode,
    component: CountryFilterComponent,
    isDisabled: defaultIsDisabled,
    emptyText: 'No Countries found',
    reset: (update) => {
      update('countries', []);
    }
  },
  {
    type: 'statusFilters',
    label: {
      name: 'IUU Status',
      icon: IUUStatusIcon
    },
    placeholder: 'Search IUU Status',
    options: Object.keys(filters.statusFilters).map((key) => ({
      key,
      label: camelToTitle(key)
    })),
    decode: defaultDecode,
    encode: defaultEncode,
    component: FilterComponent,
    isDisabled: (filters) => !filters.typeFilters.vessel,
    disabledTooltipContent: <IsDisabledTooltipContent />,
    emptyText: '',
    reset: defaultReset('statusFilters')
  },
  {
    type: 'fleetFilters',
    label: {
      name: 'Fleet Tags',
      icon: FleetTagsIcon
    },
    placeholder: 'Search fleet tags',
    options: Object.keys(filters.fleetFilters).map((key) => ({
      key,
      label: prepareFilterKeyToLabelAndCapitalizeAllWords(key)
    })),
    decode: defaultDecode,
    encode: defaultEncode,
    component: FilterComponent,
    isDisabled: (filters) => !filters.typeFilters.vessel,
    disabledTooltipContent: <IsDisabledTooltipContent />,
    emptyText: '',
    reset: defaultReset('fleetFilters')
  },
  {
    type: 'sanctionFilters',
    label: {
      name: 'Sanctions',
      icon: SanctionStatusIcon
    },
    placeholder: 'Search sanctions',
    options: Object.keys(filters.sanctionFilters).map((key) => ({
      key,
      label: prepareFilterKeyToLabelAndCapitalizeAllWords(key)
    })),
    decode: defaultDecode,
    encode: defaultEncode,
    component: FilterComponent,
    isDisabled: (filters) => !filters.typeFilters.vessel,
    disabledTooltipContent: <IsDisabledTooltipContent />,
    emptyText: '',
    reset: defaultReset('sanctionFilters')
  },
  {
    type: 'textFilters.sources',
    label: {
      name: 'Sources',
      icon: SourcesIcon
    },
    placeholder: 'Search sources',
    encode: (obj) =>
      // Encode function gets an argument like obj (returns from decode function)
      // and you can return anything you want
      Object.entries(obj)
        .filter((item) => item[1])
        .map(([key]) => key),
    decode: (values) => {
      // Every decode function must return the obj like {key: boolean}
      const result = {};
      RFMO_SOURCES.forEach((key) => {
        result[key] = false;
      });

      values.forEach((key) => {
        result[key] = true;
      });

      return result;
    },
    options: RFMO_SOURCES.map((item) => ({key: item, label: item})),
    component: FilterComponent,
    isDisabled: (filters) => !filters.typeFilters.vessel,
    disabledTooltipContent: <IsDisabledTooltipContent />,
    emptyText: '',
    reset: (update) => {
      update('textFilters.sources', []);
    }
  }
];

export const getValues = (filters, key) => {
  const keyParts = key.split('.');
  let values = {...filters};

  keyParts.forEach((key) => {
    values = values[key];
  });

  return values;
};

export const prepareValue = (filters, type, value) => {
  const [filterType, ...keys] = type.split('.');

  if (keys.length === 0) {
    return value;
  }
  const preparedValue = {...filters[filterType]};
  let link = preparedValue;
  const lastIndex = keys.length - 1;

  keys.forEach((key, index) => {
    if (index === lastIndex) {
      link[key] = value;
    }

    link = link[key];
  });

  return preparedValue;
};
