import {getAccessToken} from 'api/auth';
import axios from 'axios';

export const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json'
  }
});

apiClient.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();

    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  async (error) => Promise.reject(error)
);

const defaultGetMethod = apiClient.get.bind(apiClient);
const defaultPostMethod = apiClient.post.bind(apiClient);
const defaultPutMethod = apiClient.put.bind(apiClient);
const defaultDeleteMethod = apiClient.delete.bind(apiClient);

const get = (url, config) => {
  const abortController = new AbortController();

  const requestPromise = defaultGetMethod(url, {
    ...config,
    signal: abortController.signal
  });

  return new Promise((resolve) => {
    resolve([abortController, requestPromise]);
  });
};

const post = (url, data, config) => {
  const abortController = new AbortController();

  const requestPromise = defaultPostMethod(url, data, {
    ...config,
    signal: abortController.signal
  });

  return new Promise((resolve) => {
    resolve([abortController, requestPromise]);
  });
};

const put = (url, data, config) => {
  const abortController = new AbortController();

  const requestPromise = defaultPutMethod(url, data, {
    ...config,
    signal: abortController.signal
  });

  return new Promise((resolve) => {
    resolve([abortController, requestPromise]);
  });
};

const deleteRequest = (url, config) => {
  const abortController = new AbortController();

  const requestPromise = defaultDeleteMethod(url, {
    ...config,
    signal: abortController.signal
  });

  return new Promise((resolve) => {
    resolve([abortController, requestPromise]);
  });
};

apiClient.get = get;
apiClient.post = post;
apiClient.put = put;
apiClient.delete = deleteRequest;
