import {Box, Modal, Typography} from '@mui/material';
import {Loader} from 'components/Loader/Loader';
import {useApi} from 'hooks/useApi';
import {forwardRef, useImperativeHandle, useRef, useState} from 'react';

import defaultDialogStyles from '../../styles/dialogDefaultStyles.module.css';
import {CancelBtn} from '../CancelBtn/CancelBtn';
import {ModalContainer} from '../ModalContainer/ModalContainer';
import {ModalHeader} from '../ModalHeader/ModalHeader';
import {SubmitBtn} from '../SubmitBtn/SubmitBtn';

// eslint-disable-next-line react/display-name
export const RemoveDialog = forwardRef(({title, description, submitBtnText, service}, ref) => {
  const removeRef = useRef(null);
  const [openState, setOpenState] = useState(false);
  const [params, setParams] = useState([]);
  const endpoint = useApi({
    service,
    immediate: false
  });

  useImperativeHandle(
    ref,
    () => ({
      open: async (params) => {
        setParams(params);
        setOpenState(true);

        return new Promise((resolve) => {
          removeRef.current = (removed) => {
            resolve(removed);
            setOpenState(false);
            setParams([]);
          };
        });
      }
    }),
    []
  );

  const handleClose = () => {
    setOpenState(false);

    if (removeRef.current) {
      removeRef.current(false);
    }
  };

  const removeUserHandler = async () => {
    const result = await endpoint.execute(...params);

    if (result instanceof Error) {
      return;
    }

    if (removeRef.current) {
      removeRef.current(true);
    }
  };

  return (
    <Modal open={openState} onClose={handleClose}>
      <ModalContainer flexDirection="column">
        <Loader absolute={true} loading={endpoint.loading} />
        <ModalHeader className={defaultDialogStyles.header} onClose={handleClose} hideLine={true} absolute={true} />
        <Typography variant="h4" color="secondary" className={defaultDialogStyles.title}>
          {title}
        </Typography>
        <Typography
          variant="p"
          color="secondary"
          className={defaultDialogStyles.subTitle}
          dangerouslySetInnerHTML={{__html: description}}
        />
        <Box display="flex" alignItems="center" justifyContent="flex-end" className={defaultDialogStyles.footer}>
          <CancelBtn label="Cancel" onClick={handleClose} />
          <SubmitBtn onClick={removeUserHandler} label={submitBtnText} />
        </Box>
      </ModalContainer>
    </Modal>
  );
});
