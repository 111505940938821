import {Box} from '@mui/material';
import {Toast} from 'components/Toast/Toast';
import {useEffect, useState} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router';
import {AdminStoreWrapper} from 'stores/contexts/AdminStore';

import styles from './Admin.module.css';
import {ProfileContainer} from './components/ProfileContainer/ProfileContainer';
import {Tabs} from './components/Tabs/Tabs';

const tabLinks = ['/profile/admin/users', '/profile/admin/organizations'];

export const Admin = () => {
  const [tabId, setTabId] = useState(0);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastText, setToastText] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setTabId(tabLinks.indexOf(location.pathname) || 0);
  }, [location.pathname]);

  const handleTabChange = (event, newValue) => {
    setTabId(newValue);
    navigate(tabLinks[newValue]);
    setToastText(newValue);
  };

  return (
    <AdminStoreWrapper>
      <ProfileContainer className={styles.container}>
        <Tabs
          value={tabId}
          onChange={handleTabChange}
          tabs={[
            {id: 'users', label: 'Users'},
            {id: 'organizations', label: 'Organizations'}
          ]}
        />
        <Box>
          <Outlet />
        </Box>
      </ProfileContainer>
      <Toast open={toastOpen} onClose={() => setToastOpen(false)} message={toastText} />
    </AdminStoreWrapper>
  );
};
