import CloseIcon from '@mui/icons-material/Close';
import {Box, Button, IconButton, Stack} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import cn from 'classnames';
import {StyledTooltip} from 'components/StyledTooltip/StyledTooltip';
import {forwardRef, useEffect, useImperativeHandle, useMemo, useState} from 'react';
import {useSearchStore} from 'stores/hooks/useSearchStore';
import {excludeKeys, keydownListener} from 'utils/helpers';

import {FilterCard} from './FilterCard/FilterCard';
import styles from './SearchFilters.module.css';
import {getFiltersConfig, getValues} from './SearchFiltersConfig';
import {SearchInput} from './SearchInput/SearchInput';

// eslint-disable-next-line react/display-name
export const SearchFilters = forwardRef(({onClickSearch, onReset, onClose}, ref) => {
  const {searchFilters} = useSearchStore();
  // eslint-disable-next-line
  const [filtersConfig] = useState(getFiltersConfig(searchFilters));
  const [activeFilter, setActiveFilter] = useState(filtersConfig[0].type);
  const [appliedFilters, setAppliedFilters] = useState(JSON.stringify(searchFilters));
  const theme = useTheme();

  const applyFilters = () => {
    setAppliedFilters(JSON.stringify(searchFilters));
    onClickSearch();
  };

  useImperativeHandle(ref, () => {
    return {
      apply: () => {
        applyFilters();
      }
    };
  }, [searchFilters]);

  useEffect(() => {
    return keydownListener('Enter', () => {
      setAppliedFilters(JSON.stringify(searchFilters));
    });
  }, [searchFilters]);

  const resetFilters = (e) => {
    e.preventDefault();
    onReset();
  };

  const FilterComponent = useMemo(() => {
    const filter = filtersConfig.find((filter) => filter.type === activeFilter);
    if (filter) {
      return filter.component;
    } else {
      setActiveFilter(filtersConfig[0].type);
      return null;
    }
  }, [searchFilters, activeFilter]);

  const filterComponentProps = useMemo(() => {
    const filter = filtersConfig.find((filter) => filter.type === activeFilter);

    const props = {...filter};
    excludeKeys(props, ['component', 'label']);

    return filter ? props : {};
  }, [searchFilters, activeFilter]);

  const activeFiltersCountDct = useMemo(() => {
    const result = {};
    filtersConfig.forEach(({type}) => {
      const values = getValues(searchFilters, type);

      result[type] = Object.values(values).filter(Boolean).length;
    });

    return result;
  }, [searchFilters]);

  const filtersChanged = useMemo(() => {
    const stringifiedFilters = JSON.stringify(searchFilters);

    return stringifiedFilters !== appliedFilters;
  }, [appliedFilters, searchFilters]);

  const onSelectFilter = (filterType) => () => {
    setActiveFilter(filterType);
  };

  return (
    <form className={styles.container}>
      <Stack className={styles.filters}>
        <Box className={cn(styles.box, styles.filtersControls)}>
          <Button fullWidth color="secondary" type="text" subvariant="link" onClick={resetFilters} className={styles.resetAll}>
            Reset All Filters
          </Button>
          <Box alignItems="center" className={styles.filtersControlsEndContent}>
            {!!filtersChanged && (
              <Button
                fullWidth
                type="submit"
                variant="contained"
                onClick={applyFilters}
                className={styles.apply}
                color="secondary"
              >
                Apply
              </Button>
            )}
            <IconButton className={styles.closeFilterBtn} onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
        <Box className={cn(styles.box)}>
          <div className={styles.searchInputContainer}>
            <SearchInput className={styles.searchInput} searchIconClassName={styles.searchIcon} onSubmit={applyFilters} />
          </div>
        </Box>
        <Box className={cn(styles.box, styles.filterCards)}>
          {filtersConfig.map((filter) => (
            <StyledTooltip
              arrow
              transformArrow={false}
              backgroundColor={theme.palette.secondary.main}
              placement="top"
              enterDelay={500}
              enterNextDelay={500}
              key={filter.type}
              title={filter.isDisabled(searchFilters) ? filter.disabledTooltipContent : undefined}
            >
              <div>
                <FilterCard
                  label={filter.label.name}
                  iconComponent={filter.label.icon}
                  isDisabled={filter.isDisabled}
                  activeFiltersCount={activeFiltersCountDct[filter.type]}
                  active={filter.type === activeFilter}
                  onClick={onSelectFilter(filter.type)}
                  reset={filter.reset}
                />
              </div>
            </StyledTooltip>
          ))}
        </Box>
        <Box className={cn(styles.box, styles.filterComponent)}>
          {!!FilterComponent && <FilterComponent className={styles.filterComponentContainer} {...filterComponentProps} />}
        </Box>
      </Stack>
    </form>
  );
});
