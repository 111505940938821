import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import {Box} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {ReactComponent as CompanyImage} from 'assets/icons/icon_company_black.svg';
import {ReactComponent as PersonImage} from 'assets/icons/icon_person_black.svg';
import {ReactComponent as VesselImage} from 'assets/icons/icon_vessel_black.svg';
import cn from 'classnames';

import styles from './Scheme.module.css';

export const NodeIcons = {
  person: PersonImage,
  vessel: VesselImage,
  company: CompanyImage
};

export const Scheme = ({active, onClick, scheme}) => {
  const theme = useTheme();

  const ImageComponent = NodeIcons[scheme];

  return (
    <Box
      style={{backgroundColor: theme.palette.gray.gray50}}
      onClick={onClick}
      display="flex"
      alignItems="center"
      justifyContent="center"
      className={cn(styles.scheme, active && styles.active)}
    >
      <ImageComponent className={styles.schemeImage} />
      <VisibilityIcon style={{backgroundColor: theme.palette.gray.gray50}} className={styles.hoverImage} />
      <CloseIcon style={{backgroundColor: theme.palette.gray.gray50}} className={styles.closeImage} />
    </Box>
  );
};
