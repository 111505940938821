import {Grid} from '@mui/material';

import {PreviewSection} from '../../../components/PreviewSection/PreviewSection';

export const SingleUser = ({user}) => (
  <Grid container spacing={4}>
    <PreviewSection label="Name:" value={[user.first_name, user.last_name].filter(Boolean).join(' ')} />
    <PreviewSection label="Email Address:" value={user.email} />
  </Grid>
);
