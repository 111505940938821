import {Grid, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import cn from 'classnames';

import styles from './ColumnHeader.module.css';

export const ColumnHeader = ({label, className, endAddon, justifyContent}) => {
  const theme = useTheme();

  return (
    <Grid
      justifyContent={justifyContent}
      className={cn(styles.labelContainer, className)}
      container
      wrap="nowrap"
      alignItems="center"
    >
      <Typography className={styles.tableLabel} variant="body2" color={theme.palette.gray.gray300}>
        {label}
      </Typography>
      {endAddon}
    </Grid>
  );
};
