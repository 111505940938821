import {Box, Modal} from '@mui/material';
import {organizationServices} from 'api/services/organizations';
import {Loader} from 'components/Loader/Loader';
import {useApi} from 'hooks/useApi';
import {forwardRef, useImperativeHandle, useMemo, useRef, useState} from 'react';

import styles from '../../../Admin.module.css';
import {Footer} from '../../../components/Footer/Footer';
import {ModalContainer} from '../../../components/ModalContainer/ModalContainer';
import {ModalHeader} from '../../../components/ModalHeader/ModalHeader';
import {SubmitBtn} from '../../../components/SubmitBtn/SubmitBtn';
import {SingleUser} from './SingleUser';
import {Users} from './Users';

// eslint-disable-next-line react/display-name
export const AddUserToOrganization = forwardRef((props, ref) => {
  const confirmRef = useRef(null);
  const [currentUsers, setCurrentUsers] = useState([]);
  const [currentOrganization, setCurrentOrganization] = useState();
  const [openState, setOpenState] = useState(false);
  const addUserToOrganization = useApi({
    service: organizationServices.addUserToOrganization,
    immediate: false
  });

  useImperativeHandle(
    ref,
    () => ({
      open: async (users, organization) => {
        // mode single or bulk
        setOpenState(true);
        setCurrentUsers(users);
        setCurrentOrganization(organization);

        return new Promise((resolve) => {
          confirmRef.current = (result) => {
            // result true or false
            resolve(result);
          };
        });
      }
    }),
    []
  );

  const handleClose = () => {
    setOpenState(false);
    setCurrentUsers([]);
    confirmRef.current(false);
  };

  const confirm = async () => {
    await Promise.all(
      currentUsers.map((user) =>
        addUserToOrganization.execute({
          organizationId: currentOrganization.id,
          userId: user.id
        })
      )
    );

    setOpenState(false);
    setCurrentUsers([]);
    confirmRef.current(true);
  };

  const previewComponent = useMemo(() => {
    if (currentUsers.length === 1) {
      return <SingleUser user={currentUsers[0]} />;
    } else if (currentUsers.length > 1) {
      return <Users users={currentUsers} />;
    }
  }, [currentUsers]);

  return (
    <Modal open={openState} onClose={handleClose}>
      <ModalContainer className={styles.modalContent} flexDirection="column">
        <Loader absolute={true} loading={addUserToOrganization.loading} />
        <ModalHeader onClose={handleClose} title="Confirm Add User" />
        <Box className={styles.details}>
          {previewComponent}
          <Footer display="flex" justifyContent="flex-end">
            <SubmitBtn onClick={confirm} label="Add User" />
          </Footer>
        </Box>
      </ModalContainer>
    </Modal>
  );
});
