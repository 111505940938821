import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import {Typography} from '@mui/material';
import cn from 'classnames';

import styles from './AccessDenied.module.css';

export const AccessDenied = ({title, className, absolute}) => (
  <div className={cn(styles.container, absolute && styles.absolute, className)}>
    <DoDisturbIcon className={styles.icon} color="secondary" />
    {typeof title === 'string' ? (
      <Typography className={styles.title} color="secondary">
        {title}
      </Typography>
    ) : (
      title
    )}
  </div>
);
