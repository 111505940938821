import {createContext, useState} from 'react';
import {useSearchParams} from 'react-router-dom';
import {
  customNumberParam,
  customOrderingParam,
  customPageSizeParam,
  customStringParam,
  makeDecodeSearchParamsFunction,
  makeEncodeDataFunction
} from 'utils/queryParams';

const initialState = {
  search: '',
  page: 1,
  pageSize: 10,
  ordering: {
    sort: '',
    field: ''
  },
  setState: () => {},
  update: () => {}
};

export const AdminItemStoreContext = createContext(initialState);

const searchQueryConfig = {
  search: customStringParam('search', ''),
  page: customNumberParam('page', 0),
  pageSize: customPageSizeParam('pageSize', 10),
  ordering: customOrderingParam('ordering', {
    sort: 'desc',
    field: 'label'
  })
};

const encodeParams = makeEncodeDataFunction(searchQueryConfig);
const decodeParams = makeDecodeSearchParamsFunction(searchQueryConfig);

export const AdminItemStoreProvider = ({children}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [state, setState] = useState(decodeParams(searchParams, initialState));

  const setNewState = (state) => {
    setSearchParams(encodeParams(state));
    setState(state);
  };

  const update = (key, value) => {
    const cloned = {...state};
    cloned[key] = value;

    setNewState(cloned);
  };

  return (
    <AdminItemStoreContext.Provider
      value={{
        ...state,
        setState: setNewState,
        update
      }}
    >
      {children}
    </AdminItemStoreContext.Provider>
  );
};
