import Box from '@mui/material/Box';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {entitiesService} from 'api/services/entities';
import {CustomAutocomplete} from 'components/CustomAutocomplete/CustomAutocomplete';
import {useApi} from 'hooks/useApi';
import {useEffect, useMemo, useState} from 'react';
import {useSearchStore} from 'stores/hooks/useSearchStore';
import {removeFalseKeys} from 'utils/helpers';

import styles from '../FilterComponent.module.css';

const makeGetCountriesPayload = (filters) => {
  const sources = filters.typeFilters.vessel ? filters.textFilters.sources : [];

  return {
    searchTexts: {
      name: filters.textFilters.name,
      sources: sources
    },
    entityTypes: removeFalseKeys(filters.typeFilters, true),
    iuuStatuses: filters.typeFilters.vessel ? removeFalseKeys(filters.statusFilters, true) : undefined,
    fleetTags: filters.typeFilters.vessel ? removeFalseKeys(filters.fleetFilters, true) : undefined,
    sanctionStatuses: filters.typeFilters.vessel ? removeFalseKeys(filters.sanctionFilters, true) : undefined,
    countries: []
  };
};

const getPayloadSign = (filters) => {
  const cloned = {...filters};
  delete cloned['countries'];

  return JSON.stringify(cloned);
};

export const CountryFilterComponent = ({placeholder, type, emptyText}) => {
  const {searchFilters, updateFilter} = useSearchStore();
  const [value, setValue] = useState(searchFilters.countries);
  const countries = useApi({
    service: entitiesService.getCountries,
    payload: makeGetCountriesPayload(searchFilters),
    immediate: false
  });
  const theme = useTheme();

  useEffect(() => {
    setValue(searchFilters.countries);
  }, [type, searchFilters]);

  useEffect(() => {
    countries.execute(makeGetCountriesPayload(searchFilters));
  }, [getPayloadSign(searchFilters)]);

  const options = useMemo(() => {
    if (countries.loading) {
      return [];
    }

    return Object.entries(countries?.data?.countries || {}).map(([countryCode, {count, country_name}]) => ({
      key: countryCode,
      label: country_name,
      renderLabel: () => (
        <Box className={styles.countryItem}>
          <Typography title={country_name} className={styles.countryName}>
            {country_name}
          </Typography>
          {count > 0 && (
            <span
              style={{
                color: theme.palette.gray.gray900,
                background: theme.palette.gray.gray50
              }}
              className={styles.countryCount}
            >
              {count}
            </span>
          )}
        </Box>
      )
    }));
  }, [countries?.data, countries.loading]);

  const onChange = (newValues) => {
    setValue(newValues);
    updateFilter('countries', newValues);
  };

  return (
    <Box className={styles.box}>
      <CustomAutocomplete
        placeholder={placeholder}
        options={options}
        loading={countries.loading}
        value={value}
        onChange={onChange}
        emptyText={emptyText}
      />
    </Box>
  );
};
