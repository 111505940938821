import {createContext, useState} from 'react';

const initialState = {
  injectComponentToHeader: () => {},
  clearHeader: () => {},
  header: undefined,
  footer: true,
  hideFooter: () => {},
  showFooter: () => {},
  layoutClassName: undefined,
  setLayoutClassName: () => {}
};

export const LayoutContext = createContext(initialState);

export const LayoutProvider = ({children}) => {
  const [header, setHeader] = useState();
  const [footer, setFooter] = useState(true);
  const [layoutClassName, setLayoutClassName] = useState();

  const injectComponentToHeader = (reactNode) => {
    setHeader(reactNode);
  };

  const clearHeader = () => {
    setHeader();
  };

  const hideFooter = () => {
    setFooter(false);
  };

  const showFooter = () => {
    setFooter(true);
  };

  return (
    <LayoutContext.Provider
      value={{
        injectComponentToHeader,
        clearHeader,
        header,
        footer,
        showFooter,
        hideFooter,
        layoutClassName,
        setLayoutClassName
      }}
    >
      {children}
    </LayoutContext.Provider>
  );
};
