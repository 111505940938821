import Box from '@mui/material/Box';

import styles from './Popup.module.css';

export const GraphPopup = ({children, outerChildren}) => {
  return (
    <>
      <div className={styles.popoverArrow} data-popper-arrow />
      <Box className={styles.popoverBox}>
        <Box className={styles.popoverInnerBox}>{children}</Box>
        {outerChildren}
      </Box>
    </>
  );
};
