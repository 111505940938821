import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {useTheme} from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import {ReactComponent as FilterIcon} from 'assets/icons/icon_filter_solid.svg';
import cn from 'classnames';
import {useRef} from 'react';

import styles from '../QuickSearch.module.css';
import {QuickSearchInputEndAdornment} from './QuickSearchInputEndAdornment';

export const QuickSearchInputs = (props) => {
  const {searchText, onClickFilters, onClickSearch} = props;
  const inputRef = useRef(null);
  const theme = useTheme();

  const clear = () => {
    searchText.setValue('');
  };

  return (
    <Grid container spacing={5} justifyContent="space-between">
      <Grid item xs={12}>
        <TextField
          ref={inputRef}
          fullWidth
          variant="outlined"
          placeholder="Search by name or other unique identifiers"
          value={searchText.input.value}
          onChange={(event) => searchText.changeInput(event)}
          className={styles.textField}
          sx={{background: theme.palette.white.main}}
          InputProps={{
            endAdornment: (
              <QuickSearchInputEndAdornment onClickFilters={onClickFilters} onClear={clear} onClickSearch={onClickSearch} />
            )
          }}
        />
        <Box className={styles.mobileTextFieldButtonsContainer}>
          <div
            className={cn(styles.filterBtn, styles.mobileTextFieldBtn)}
            style={{background: theme.palette.grey.A100}}
            onClick={onClickFilters}
          >
            <Typography className={styles.filterBtnText} variant="body1" color="secondary.main">
              Filter Results
            </Typography>
            <FilterIcon className={styles.filterIcon} />
          </div>
        </Box>
      </Grid>
    </Grid>
  );
};
