import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import backgroundImage from 'assets/backgrounds/background_missing.png';
import {Background} from 'components/Background/Background';

import styles from './NotFoundPage.module.css';

export const NotFoundPage = () => (
  <Background backgroundImage={backgroundImage} className={styles.background}>
    <Container maxWidth="sm" className={styles.container}>
      <Stack spacing={10}>
        <Box>
          <Typography variant="h2" align="center" color="white.main" className={styles.textStyle}>
            404
          </Typography>
        </Box>
        <Card raised elevation={2}>
          <CardContent className={styles.cardContent}>
            <Stack spacing={10}>
              <Typography variant="h5" className={styles.textStyle}>
                Page not found
              </Typography>
              <Typography align="center">The page you&apos;re looking for doesn&apos;t exist.</Typography>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  </Background>
);
