import 'tippy.js/dist/tippy.css';
import 'tippy.js/dist/backdrop.css';
import 'tippy.js/animations/shift-away.css';

import LoadingButton from '@mui/lab/LoadingButton';
import {Box, Button, Grid, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {entitiesService} from 'api/services/entities';
import {ReactComponent as BackButtonIcon} from 'assets/icons/icon_back_btn.svg';
import {ReactComponent as CollapseIcon} from 'assets/icons/icon_collapse.svg';
import {ReactComponent as DownloadIcon} from 'assets/icons/icon_download.svg';
import {ReactComponent as ExtendIcon} from 'assets/icons/icon_extend.svg';
import {ReactComponent as InfoIcon} from 'assets/icons/icon_info.svg';
import {ReactComponent as MinusIcon} from 'assets/icons/icon_minus.svg';
import {ReactComponent as PlusIcon} from 'assets/icons/icon_plus.svg';
import {ReactComponent as ResetIcon} from 'assets/icons/icon_reset.svg';
import {StyledTooltip} from 'components/StyledTooltip/StyledTooltip';
import cytoscape from 'cytoscape';
import cydagre from 'cytoscape-dagre';
import nodeHtmlLabel from 'cytoscape-node-html-label';
import popper from 'cytoscape-popper';
import {useApi} from 'hooks/useApi';
import {debounce} from 'lodash';
import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router';
import {capitalize} from 'utils/helpers';

import {useNetworkGraphContext} from '../../../../stores/hooks/useNetworkGraphContext';
import cytoscapeStyles from './cytoscapeStyles';
import {EdgesLayer} from './Layers/EdgesLayer';
import {NodesLayer} from './Layers/NodesLayer';
import {NetworkGraphManager} from './NetworkGraphManager';
import styles from './NetworksGraph.module.css';
import {prepareEdges, prepareNodes} from './networksGraphTools';

cytoscape.use(popper);
cydagre(cytoscape);

nodeHtmlLabel(cytoscape);

export const NetworksGraph = ({network, detailsData, triton_id, onClickNode}) => {
  const theme = useTheme();
  const graphRef = useRef(null);
  const navigate = useNavigate();
  const {cyto, setCyto} = useNetworkGraphContext();
  const badgesRef = useRef([]);
  const [layout, setLayout] = useState('dagre');
  const entityDownload = useApi({
    service: entitiesService.entityDownload,
    immediate: false
  });
  const [nodes] = useState(prepareNodes(network?.nodes));
  const [edges] = useState(prepareEdges(network?.edges ?? []));
  const networkGraphManagerRef = useRef(new NetworkGraphManager(network?.nodes ?? [], network?.edges ?? []));

  useEffect(
    () => () => {
      badgesRef.current.forEach((badge) => {
        badge.badge?.destroy();
      });
      badgesRef.current = [];
    },
    []
  );

  const onFitToPage = () => {
    cyto.animate(
      {
        fit: {
          eles: graphRef,
          padding: 100
        }
      },
      {duration: 100}
    );
  };

  const calculateGraphConnectedness = () => {
    const connectionsDct = {};
    edges.forEach((item) => {
      const targetId = item.data.target;
      if (connectionsDct[targetId]) {
        connectionsDct[targetId] += 1;
      } else {
        connectionsDct[targetId] = 1;
      }
    });

    return Math.max(...Object.entries(connectionsDct).map(([, value]) => value));
  };

  const drawGraph = () => {
    const maxConnections = calculateGraphConnectedness();

    const cy = cytoscape({
      container: graphRef.current,
      avoidOverlap: true,
      style: cytoscapeStyles(triton_id, maxConnections),
      layout: {
        name: layout,
        rows: 5
      },
      elements: {
        nodes: nodes,
        edges: edges
      }
    });

    cy.maxZoom(4);
    cy.minZoom(0.2);

    setCyto(cy);
  };

  useEffect(() => {
    const handler = debounce(onFitToPage, 250);
    window.addEventListener('resize', handler);

    if (cyto?.animate) {
      onFitToPage();
    }

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [cyto]);

  useEffect(() => {
    drawGraph();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [network, layout]);

  const hideAllTippy = () => {
    cyto.edges().forEach((ele) => ele.badge?.destroy());
  };

  const goBack = () => {
    hideAllTippy();
    navigate(-1);
  };

  const controlButtonOnHold = (action) => (e) => {
    const interval = setInterval(action, 150);
    const onMouseUp = () => {
      clearInterval(interval);
      e.target.removeEventListener('mouseup', onMouseUp);
    };

    e.target.addEventListener('mouseup', onMouseUp);
  };

  const getNetworkTitle = () => {
    const nodes = network?.nodes;
    if (!nodes || nodes.length === 0) {
      return;
    }

    return `Network - ${capitalize(nodes[0].entity.entity_type)} - ${nodes[0].entity.entityType}`;
  };

  const onZoomIn = (duration = 50) => {
    if (cyto.zoom() >= 4) {
      return;
    }

    cyto.animate({zoom: cyto.zoom() + 0.2, panBy: {x: -100, y: -50}}, {duration});
  };

  const onZoomOut = (duration = 50) => {
    if (cyto.zoom() <= 0.2) {
      return;
    }

    cyto.animate({zoom: cyto.zoom() - 0.2, panBy: {x: 100, y: 50}}, {duration});
  };

  const onReset = () => {
    setLayout('dagre');
    onFitToPage();
    drawGraph();
  };

  const onDownload = () => {
    entityDownload.execute(triton_id).then((responseData) => {
      const url = responseData?.url;
      if (!url) {
        return;
      }

      const downloadLink = document.createElement('a');
      downloadLink.download = detailsData?.label ? `${detailsData.label}.zip` : `${triton_id}.zip`;
      downloadLink.href = url;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.remove();
    });
  };

  const collapseAllNodes = () => {
    const root = networkGraphManagerRef.current.getRoot();
    const descendants = networkGraphManagerRef.current.descendants(root.id).map(({id}) => id);
    const descendantsElements = cyto.nodes().filter((el) => descendants.includes(el.data().id));

    descendantsElements.forEach((element) => {
      element.hide();
    });
  };

  const extendAllNodes = () => {
    const root = networkGraphManagerRef.current.getRoot();
    const descendants = networkGraphManagerRef.current.descendants(root.id).map(({id}) => id);
    const descendantsElements = cyto.nodes().filter((el) => descendants.includes(el.data().id));

    descendantsElements.forEach((element) => {
      element.show();
    });
  };

  return (
    <>
      <Grid container spacing={5} className={styles.container}>
        <Box className={styles.header}>
          <Box className={styles.headerStart}>
            <Grid onClick={goBack} item xs={12} className={styles.backBtn}>
              <BackButtonIcon className={styles.backBtnIcon} style={{fill: theme.palette.secondary.main}} />
              <Typography component="span" color="secondary" className={styles.backBtnText}>
                Back
              </Typography>
            </Grid>
            <Typography title={getNetworkTitle()} variant="h6" color="secondary" className={styles.networksTitle}>
              {getNetworkTitle()}
            </Typography>
            <StyledTooltip
              arrow
              title={
                <Typography className={styles.disclaimerText}>
                  {/* eslint-disable-next-line max-len */}
                  Ownership information is currently being updated. If it is not included in Triton, it is either unavailable
                  through current sources or analysts have yet to complete the chart. To ask about the status of updates, email
                  the C4ADS team at contact@triton.fish.
                </Typography>
              }
              backgroundColor={theme.palette.secondary.main}
              placement="bottom-start"
            >
              <InfoIcon style={{fill: theme.palette.secondary.main}} className={styles.disclaimerIcon} />
            </StyledTooltip>
          </Box>
          <Box className={styles.headerEnd}>
            <StyledTooltip
              arrow
              title={<Typography className={styles.disclaimerText}>Collapse all connections.</Typography>}
              backgroundColor={theme.palette.secondary.main}
              placement="bottom-end"
            >
              <Button
                sx={{background: theme.palette.gray.lightgray}}
                className={styles.headerButton}
                onClick={() => collapseAllNodes()}
              >
                <CollapseIcon style={{color: theme.palette.secondary.main}} className={styles.headerButtonIcon} />
              </Button>
            </StyledTooltip>
            <StyledTooltip
              arrow
              title={<Typography className={styles.disclaimerText}>Expand all connections.</Typography>}
              backgroundColor={theme.palette.secondary.main}
              placement="bottom-end"
            >
              <Button
                sx={{background: theme.palette.gray.lightgray}}
                className={styles.headerButton}
                onClick={() => extendAllNodes()}
              >
                <ExtendIcon style={{color: theme.palette.secondary.main}} className={styles.headerButtonIcon} />
              </Button>
            </StyledTooltip>
            <LoadingButton
              sx={{background: theme.palette.gray.lightgray}}
              loading={entityDownload.loading}
              className={styles.headerButton}
              onClick={onDownload}
            >
              <DownloadIcon style={{fill: theme.palette.secondary.main}} className={styles.headerButtonIcon} />
            </LoadingButton>
          </Box>
        </Box>
        <div className={styles.networkGraphContainer}>
          <div className={styles.controlsButtons}>
            <ControlButton
              icon={<PlusIcon className={styles.controlButtonIcon} />}
              onMouseDown={controlButtonOnHold(() => {
                onZoomIn(20);
              })}
              onClick={() => {
                onZoomIn(20);
              }}
            />
            <ControlButton
              icon={<MinusIcon className={styles.controlButtonIcon} />}
              onMouseDown={controlButtonOnHold(() => {
                onZoomOut(20);
              })}
              onClick={() => {
                onZoomOut(20);
              }}
            />
            <ControlButton icon={<ResetIcon className={styles.controlButtonIcon} />} onClick={onReset} />
          </div>
          <div className={styles.networkGraphWrapper}>
            <div className={styles.graph}>
              <div ref={graphRef} className={styles.graphCanvasContainer} />
              <NodesLayer nodes={nodes} networkGraphManager={networkGraphManagerRef.current} onClickNode={onClickNode} />
              <EdgesLayer nodes={nodes} />
            </div>
          </div>
        </div>
      </Grid>
      <div>&nbsp;</div>
    </>
  );
};

const ControlButton = ({icon, onClick, onMouseDown}) => (
  <div onClick={onClick} onMouseDown={onMouseDown} className={styles.controlButton}>
    {icon}
  </div>
);
