import {parse} from 'csv-parse/browser/esm';

import {COUNTRIES} from '../constants/countries';

export function capitalize(str) {
  return str[0].toUpperCase() + str.slice(1).toLowerCase();
}

export function camelToTitle(str) {
  str = str.replace(/([A-Z])/g, ' $1');
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function prepareFilterKeyToLabel(tag) {
  return capitalize(tag.replace('_', ' '));
}

export function prepareFilterKeyToLabelAndCapitalizeAllWords(value) {
  if (!value || value === '') {
    return 'N/A';
  }

  return value.split('_').map(capitalize).join(' ');
}

export function getCountryNameByCountryCode(countryCode) {
  const country = COUNTRIES.find((country) => country.code === countryCode);
  return country ? country.label : undefined;
}

export function excludeKeys(obj, keys) {
  keys.forEach((key) => {
    delete obj[key];
  });
}

export const getValue = (address, data) => {
  const path = address.split('.');

  let value = {...data};
  path.forEach((key) => {
    value = value[key];
  });
  return value;
};

export const setValue = (data, address, value) => {
  const path = address.split('.');

  let obj = data;
  path.slice(0, path.length - 1).forEach((key) => {
    obj = obj[key];
  });

  obj[path[path.length - 1]] = value;
};

export const readFile = async (file) => {
  const stream = file.stream();
  const reader = stream.getReader();

  return new Promise((resolve) => {
    let fileContentString = '';
    const textDecoder = new TextDecoder('utf-8');
    const readFunction = ({done, value}) => {
      if (done) {
        resolve({fileName: file.name, value: fileContentString});
        return;
      }

      fileContentString += textDecoder.decode(value);
      reader.read().then(readFunction);
    };

    reader.read().then(readFunction);
  });
};

export const deepClone = (obj) => JSON.parse(JSON.stringify(obj));

export const removeArrayDuplicates = (arr) => {
  const result = [];
  arr.forEach((item) => {
    const itemIsExist = result.find((resultItem) => JSON.stringify(resultItem) === JSON.stringify(item));

    if (!itemIsExist) {
      result.push(item);
    }
  });

  return result;
};

export const removeFalseKeys = (obj, returnUndefinedIfNoKeys = false) => {
  const result = {...obj};

  Object.keys(result).forEach((key) => {
    if (!result[key]) {
      delete result[key];
    }
  });

  return Object.keys(result).length === 0 && returnUndefinedIfNoKeys ? undefined : result;
};

export const keydownListener = (keyCode, handler) => {
  const keydownHandler = (e) => {
    if (e.code === keyCode) {
      handler();
    }
  };
  window.addEventListener('keydown', keydownHandler);

  return () => {
    window.removeEventListener('keydown', keydownHandler);
  };
};

export const csvParser = (fileName, csvFileContent) => {
  return new Promise((resolve, reject) => {
    parse(csvFileContent, {columns: true}, (err, data) => {
      if (!err) {
        resolve(data);
      } else {
        reject(err?.message);
      }
    });
  });
};
