import {Modal} from '@mui/material';
import {organizationServices} from 'api/services/organizations';
import {Loader} from 'components/Loader/Loader';
import {useApi} from 'hooks/useApi';
import {forwardRef, useImperativeHandle, useMemo, useRef, useState} from 'react';

import {Footer} from '../../components/Footer/Footer';
import {ModalContainer} from '../../components/ModalContainer/ModalContainer';
import {ModalHeader} from '../../components/ModalHeader/ModalHeader';
import {SubmitBtn} from '../../components/SubmitBtn/SubmitBtn';
import {MultipleOrganizationPreview} from './MultipleOrganizationPreview';
import {OrganizationPreview} from './OrganizationPreview';

// eslint-disable-next-line react/display-name,no-unused-vars
export const ConfirmOrganizationDetails = forwardRef((props, ref) => {
  const confirm = useRef(null);
  const [openState, setOpenState] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);
  const createOrganization = useApi({
    service: organizationServices.create,
    immediate: false
  });

  useImperativeHandle(
    ref,
    () => ({
      open: async (organizations) => {
        setOrganizations(organizations);
        setOpenState(true);

        return new Promise((resolve) => {
          confirm.current = (result) => {
            resolve(result);
          };
        });
      }
    }),
    []
  );

  const handleClose = () => {
    setOpenState(false);
    setOrganizations([]);

    if (confirm.current) {
      confirm.current({confirmed: false});
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    const result = await Promise.all(
      organizations
        .map((organization) => {
          const cloned = {...organization};
          delete cloned['admins'];

          return cloned;
        })
        .map(createOrganization.execute)
    );
    setLoading(false);

    setOpenState(false);
    setOrganizations([]);

    if (confirm.current) {
      const newOrganizations = organizations.map((organization, index) => ({
        ...organization,
        id: result[index].id
      }));
      confirm.current({confirmed: true, organizations: newOrganizations});
    }
  };

  const organizationViewComponent = useMemo(() => {
    if (organizations.length > 1) {
      return <MultipleOrganizationPreview organizations={organizations} />;
    } else if (organizations.length === 1) {
      return <OrganizationPreview organization={organizations[0]} />;
    }
  }, [organizations]);

  return (
    <Modal open={openState} onClose={handleClose}>
      <ModalContainer flexDirection="column">
        <Loader absolute={true} loading={loading} />
        <ModalHeader onClose={handleClose} title="Confirm Organization Details" />
        {organizationViewComponent}
        <Footer width="100%" display="flex" justifyContent="flex-end">
          <SubmitBtn label="Submit" onClick={onSubmit} />
        </Footer>
      </ModalContainer>
    </Modal>
  );
});
