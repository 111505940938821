import {useEffect, useState} from 'react';
import {moment} from 'utils/time';

export function useResizeEvent() {
  const [event, setEvent] = useState(moment().valueOf());

  useEffect(() => {
    const updateEvent = () => setEvent(moment().valueOf());

    window.addEventListener('resize', updateEvent);
    updateEvent();

    return () => window.removeEventListener('resize', updateEvent);
  }, []);

  return {event};
}
