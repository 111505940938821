import {Box, Tooltip, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {ReactComponent as SanctionStatusIcon} from 'assets/icons/icon_table_sanction.svg';
import cn from 'classnames';
import {NoMaxWidthTooltip} from 'components/NoMaxWidthTooltip/NoMaxWidthTooltip';
import {StatusChip} from 'components/StatusChip/StatusChip';
import {SANCTION_STATUS_DCT} from 'constants/entities';
import {prepareFilterKeyToLabel} from 'utils/helpers';

import styles from './SanctionStatus.module.css';

const isVessel = (entity_type) => entity_type === 'vessel';

export const SanctionStatus = ({iconMode, sanction_status, entity_type}) => {
  const theme = useTheme();
  let value = sanction_status;
  value = value === 'no_information' ? null : value;

  if (!value || !isVessel(entity_type)) {
    return '';
  }

  const status = value ? SANCTION_STATUS_DCT[value] : 'default';
  const label = prepareFilterKeyToLabel(value).split(' ')[0];

  return iconMode ? (
    <NoMaxWidthTooltip
      arrow
      title={
        <Box className={styles.tooltipTitle} display="flex" alignItems="center">
          <Typography className={styles.tooltipText}>This vessel has an</Typography>
          <Typography className={cn(styles.tooltipText, styles.tooltipStatusText)}>{`${label} sanction`}</Typography>
          <Typography className={styles.tooltipText}>status.</Typography>
        </Box>
      }
      placement="top-end"
      backgroundColor={theme.palette.secondary.main}
    >
      <SanctionStatusIcon
        style={{fill: value === 'active_sanctions' ? theme.palette.secondary.secondary200 : theme.palette.gray.gray100}}
      />
    </NoMaxWidthTooltip>
  ) : (
    <Tooltip title="Sanction Status" placement="top">
      <div>
        <StatusChip status={status} label={label} />
      </div>
    </Tooltip>
  );
};
