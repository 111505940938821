import AddIcon from '@mui/icons-material/Add';
import DoneIcon from '@mui/icons-material/Done';
import {Box, Button, Modal, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {organizationServices} from 'api/services/organizations';
import {CustomAutocompleteInput} from 'components/CustomInput/CustomAutocompleteInput';
import {CustomInput} from 'components/CustomInput/CustomInput';
import {Loader} from 'components/Loader/Loader';
import {ErrorMessage} from 'components/Messages/ErrorMessage';
import {useApi} from 'hooks/useApi';
import {useImperativeRef} from 'hooks/useImperativeRef';
import {forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState} from 'react';
import {useAdminStore} from 'stores/hooks/useAdminStore';

import styles from '../../Admin.module.css';
import {Footer} from '../../components/Footer/Footer';
import {ModalContainer} from '../../components/ModalContainer/ModalContainer';
import {ModalHeader} from '../../components/ModalHeader/ModalHeader';
import {RemoveDialog} from '../../components/RemoveDialog/RemoveDialog';
import {SectionHeader} from '../../components/SectionHeader/SectionHeader';
import {SubmitBtn} from '../../components/SubmitBtn/SubmitBtn';
import {AddUserToOrganization} from './AddUserToOrganization/AddUserToOrganization';
import {ConfirmEditedOrganizationDetails} from './ConfirmEditedOrganizationDetails/ConfirmEditedOrganizationDetails';
import editOrganizationStyles from './EditOrganization.module.css';
import {InviteUser} from './InviteUser/InviteUser';
import {OrganizationUsers} from './OrganizationUsers';

// eslint-disable-next-line react/display-name
export const EditOrganization = forwardRef((props, ref) => {
  const {users, organizations: storedOrganizations} = useAdminStore();
  const organizations = storedOrganizations.data || [];
  const theme = useTheme();
  const [openState, setOpenState] = useState(false);
  const [hideModal, setHideModal] = useState(false);
  const [currentOrganization, setCurrentOrganization] = useState();
  const [organizationNameError, setOrganizationNameError] = useState();
  const [organizationName, setOrganizationName] = useState(currentOrganization?.name);
  const [userForRemove, setUserForRemove] = useState();
  const [allUsers, setAllUsers] = useState([]);
  const [organizationUsers, setOrganizationUsers] = useState([]);

  const hideModalConfig = {
    beforeCall: () => {
      setHideModal(true);
    },
    afterCall: () => {
      setHideModal(false);
    }
  };
  const setOrganization = useRef(null);
  const [confirmOrganizationDetails, setConfirmOrganizationDetails] = useImperativeRef(null, hideModalConfig, ['open']);
  const [addUserToOrganization, setAddUserToOrganization] = useImperativeRef(null, hideModalConfig, ['open']);
  const [inviteUserRef, setInviteUserRef] = useImperativeRef(null, hideModalConfig, ['open']);
  const [removeUser, setRemoveUser] = useImperativeRef(null, hideModalConfig, ['open']);

  const organizationUsersApi = useApi({
    service: organizationServices.organizationUsers,
    immediate: false
  });

  useEffect(() => {
    if (users.data) {
      setAllUsers(users.data);
    }
  }, [users.data]);

  useEffect(() => {
    if (organizationUsersApi.data) {
      setOrganizationUsers(
        organizationUsersApi.data.map(({id, user}) => ({
          ...user,
          membershipId: id
        }))
      );
    }
  }, [organizationUsersApi.data]);

  useEffect(() => {
    setOrganizationName(currentOrganization?.name);
  }, [currentOrganization]);

  useImperativeHandle(
    ref,
    () => ({
      open: async (organizationId) => {
        setOpenState(true);

        setCurrentOrganization(organizations.find((organization) => organization.id === organizationId));
        organizationUsersApi.execute(organizationId);

        return new Promise((resolve) => {
          setOrganization.current = (organization) => {
            // organization obj or undefined
            resolve(organization);
          };
        });
      }
    }),
    [organizations]
  );

  const userOptions = useMemo(() => {
    const result = [];
    const organizationUsersIds = organizationUsers.map((user) => user.id);

    allUsers.forEach((user) => {
      if (!organizationUsersIds.includes(user.id)) {
        result.push(user);
      }
    });

    return result.filter((user) => !user.organization);
  }, [organizationUsers, allUsers]);

  const checkOrganizationName = (newOrganizationName) => {
    let allOrganizationsNames = organizations || [];

    allOrganizationsNames = allOrganizationsNames.map((organization) => organization.name);

    const index = allOrganizationsNames.findIndex((name) => name === currentOrganization?.name);
    if (index !== -1) {
      allOrganizationsNames.splice(index, 1);
    }

    return !allOrganizationsNames.includes(newOrganizationName);
  };

  const organizationNameShowErrorMessage = (newOrganizationName, submit = false) => {
    if (submit === false && organizationNameError === undefined) {
      return;
    }

    if (newOrganizationName === '') {
      setOrganizationNameError('Organization name cannot be empty.');
      return;
    }

    if (!checkOrganizationName(newOrganizationName)) {
      setOrganizationNameError('Current organization name already used.');
    } else {
      setOrganizationNameError('');
    }
  };

  const handleClose = () => {
    setOpenState(false);
    setCurrentOrganization();
    setOrganizationNameError();
    if (setOrganization.current) {
      setOrganization.current(undefined);
    }
  };

  const save = async () => {
    organizationNameShowErrorMessage(organizationName, true);
    if (!checkOrganizationName(organizationName) || organizationName === '') {
      return;
    }

    const result = await confirmOrganizationDetails.current.open({
      id: currentOrganization.id,
      name: organizationName
    });

    if (result.confirmed) {
      setOrganization.current(result.organization);
      setCurrentOrganization(result.organization);
    } else {
      setOrganization.current(undefined);
    }
  };

  const inviteUser = async (mode) => {
    const invitedUsers = await inviteUserRef.current.open(mode);

    if (invitedUsers) {
      organizationUsersApi.execute(currentOrganization.id);
      storedOrganizations.execute();
      users.execute();
    }
  };

  const openBulkUserImport = useCallback(() => {
    inviteUser('bulk');
  }, [inviteUserRef.current]);

  const openSingleUserImport = useCallback(() => {
    inviteUser('single');
  }, [inviteUserRef.current]);

  const organizationNameOnChange = (e) => {
    const value = e.target.value;
    organizationNameShowErrorMessage(value);
    setOrganizationName(value);
  };

  const userOnRemove = async (userId) => {
    setUserForRemove(organizationUsers.find((user) => user.id === userId));
    const result = await removeUser.current.open([{organizationId: currentOrganization?.id, userId}]);

    if (result) {
      const index = organizationUsers.findIndex((user) => user.id === userId);
      const cloned = organizationUsers.slice();
      cloned.splice(index, 1);
      setOrganizationUsers(cloned);
      storedOrganizations.execute();
      users.execute();
    }
  };

  const getUserName = (user) => {
    const nameParts = [user?.first_name, user?.last_name];

    return nameParts.filter(Boolean).join(' ');
  };

  const userOnSelect = async (e, value) => {
    const user = userOptions.find((user) => value?.key === user.id);
    if (!user) {
      return;
    }

    const result = await addUserToOrganization.current.open([user], currentOrganization);

    if (result) {
      setOrganizationUsers((prev) => [user].concat(prev));
      users.execute();
      storedOrganizations.execute();
    }
  };

  const autoCompleteOptions = useMemo(() => {
    const prefix = {key: 'autocompletePrefix', label: '', prefix: true};
    const users = userOptions.map((user) => ({
      key: user.id,
      label: getUserName(user)
    }));

    return [prefix, ...users];
  }, [userOptions]);

  return (
    <Modal open={openState} onClose={handleClose} style={{opacity: hideModal ? 0 : 1}}>
      <ModalContainer className={styles.modalContent} flexDirection="column">
        <RemoveDialog
          ref={setRemoveUser}
          title={`Remove User from ${currentOrganization?.name}`}
          description={`Are you sure you want to remove ${getUserName(userForRemove)} from ${currentOrganization?.name} ?`}
          submitBtnText="Remove"
          service={organizationServices.removeUserFromOrganization}
        />
        <Loader absolute={true} loading={users.loading || organizationUsersApi.loading} />
        <InviteUser ref={setInviteUserRef} users={users?.data || []} organization={currentOrganization} />
        <AddUserToOrganization ref={setAddUserToOrganization} />
        <ConfirmEditedOrganizationDetails ref={setConfirmOrganizationDetails} />
        <ModalHeader onClose={handleClose} title={`${currentOrganization?.name} - Edit`} />
        <Box className={styles.details}>
          <Box display="flex" alignItems="center" justifyContent="space-between">
            <Typography className={editOrganizationStyles.usersCount} style={{color: theme.palette.gray.gray275}}>
              {userOptions?.length || 0}
              {' '}
              Users
            </Typography>
            <Box display="flex" alignItems="center" className={editOrganizationStyles.usersContainer}>
              <CustomAutocompleteInput
                className={editOrganizationStyles.autocomplete}
                required={true}
                placeholder={`Add User to ${currentOrganization?.name}`}
                options={autoCompleteOptions}
                isOptionEqualToValue={(option, value) => option.key === value.key}
                renderOption={(props, {prefix, label}) =>
                  !prefix ? (
                    <Box
                      {...props}
                      display="flex"
                      alignItems="center"
                      className={editOrganizationStyles.inviteUserBtn}
                      style={{border: 'none'}}
                    >
                      <Typography className={editOrganizationStyles.userOption}>{label}</Typography>
                    </Box>
                  ) : (
                    <Box
                      key={props.key}
                      display="flex"
                      alignItems="center"
                      className={editOrganizationStyles.inviteUserBtn}
                      onClick={openSingleUserImport}
                      style={{borderColor: theme.palette.gray.gray100}}
                    >
                      <AddIcon className={editOrganizationStyles.inviteUserIcon} />
                      <Typography color="secondary" className={editOrganizationStyles.userOption}>
                        Invite User to Triton...
                      </Typography>
                    </Box>
                  )
                }
                value={{key: 'autocompletePrefix', label: '', prefix: true}}
                disableValueChanging={true}
                onChange={userOnSelect}
              />
              <SubmitBtn
                className={editOrganizationStyles.usersImportBtn}
                onClick={openBulkUserImport}
                prefix={<AddIcon className={editOrganizationStyles.usersImportBtnIcon} color="white" />}
                label={<Typography className={editOrganizationStyles.usersImportText}>Bulk User Import</Typography>}
              />
            </Box>
          </Box>
          <Box className={editOrganizationStyles.section}>
            <SectionHeader title="Organization Name" />
            <CustomInput placeholder="Organization Name" value={organizationName} onChange={organizationNameOnChange} />
            {!!organizationNameError?.length && <ErrorMessage message={organizationNameError} />}
          </Box>
          <Box className={editOrganizationStyles.section}>
            <SectionHeader title={`Users in ${organizationName}`} />
            <OrganizationUsers users={organizationUsers} onRemove={userOnRemove} />
          </Box>
          <Footer display="flex" justifyContent="flex-end">
            <Button variant="text" className={editOrganizationStyles.cancelBtn} onClick={handleClose}>
              Cancel
            </Button>
            {organizationName !== currentOrganization?.name && (
              <SubmitBtn
                className={editOrganizationStyles.saveBtn}
                prefix={<DoneIcon className={editOrganizationStyles.editGroupBtnIcon} color="white" />}
                onClick={save}
                label="Save"
              />
            )}
          </Footer>
        </Box>
      </ModalContainer>
    </Modal>
  );
});
