import {FLEET_FILTERS, SANCTION_FILTERS, STATUS_FILTERS, TEXT_FILTERS, TYPE_FILTERS} from 'constants/entities';
import {useMemo, useState} from 'react';

const defaults = {
  textFilters: TEXT_FILTERS,
  typeFilters: TYPE_FILTERS,
  statusFilters: STATUS_FILTERS,
  fleetFilters: FLEET_FILTERS,
  sanctionFilters: SANCTION_FILTERS
};

export function useEntityFilters() {
  const [textFilters, setTextFilters] = useState(defaults.textFilters);
  const [typeFilters, setTypeFilters] = useState(defaults.typeFilters);
  const [statusFilters, setStatusFilters] = useState(defaults.statusFilters);
  const [fleetFilters, setFleetFilters] = useState(defaults.fleetFilters);
  const [sanctionFilters, setSanctionFilters] = useState(defaults.sanctionFilters);
  const filterValues = useMemo(
    () => ({
      textFilters,
      typeFilters,
      statusFilters,
      fleetFilters,
      sanctionFilters
    }),
    [textFilters, typeFilters, statusFilters, fleetFilters, sanctionFilters]
  );
  const filterSetters = {
    setTextFilters,
    setTypeFilters,
    setStatusFilters,
    setFleetFilters,
    setSanctionFilters
  };
  const filterSetterMap = {
    textFilters: 'setTextFilters',
    typeFilters: 'setTypeFilters',
    statusFilters: 'setStatusFilters',
    fleetFilters: 'setFleetFilters',
    sanctionFilters: 'setSanctionFilters'
  };

  const updateFilters = (key, values) => {
    filterSetters[filterSetterMap[key]](values);
  };

  const resetFilters = () => {
    Object.keys(filterValues).forEach((key) => {
      const values = defaults[key];

      filterSetters[filterSetterMap[key]](values);
    });
  };

  return {
    values: filterValues,
    update: updateFilters,
    reset: resetFilters
  };
}
