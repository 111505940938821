import {Modal} from '@mui/material';
import {usersService} from 'api/services/users';
import {Loader} from 'components/Loader/Loader';
import {useApi} from 'hooks/useApi';
import {forwardRef, useImperativeHandle, useMemo, useRef, useState} from 'react';

import {Footer} from '../../../components/Footer/Footer';
import {ModalContainer} from '../../../components/ModalContainer/ModalContainer';
import {ModalHeader} from '../../../components/ModalHeader/ModalHeader';
import {SubmitBtn} from '../../../components/SubmitBtn/SubmitBtn';
import {MultipleUsersPreview} from './MultipleUsersPreview';
import {UserPreview} from './UserPreview';

// eslint-disable-next-line react/display-name,no-unused-vars
export const ConfirmUserDetails = forwardRef((props, ref) => {
  const confirm = useRef(null);
  const [openState, setOpenState] = useState(false);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const createUsers = useApi({
    service: usersService.bulkUserCreation,
    immediate: false
  });

  useImperativeHandle(
    ref,
    () => ({
      open: async (users) => {
        setUsers(users);
        setOpenState(true);

        return new Promise((resolve) => {
          confirm.current = (result) => {
            resolve(result);
          };
        });
      }
    }),
    []
  );

  const handleClose = () => {
    setOpenState(false);
    setUsers([]);

    if (confirm.current) {
      confirm.current({confirmed: false});
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    const result = await createUsers.execute(
      users.map((user) => {
        const cloned = {...user};
        delete cloned['userIndex'];
        return cloned;
      })
    );
    setLoading(false);

    let responseData;
    let updateUsers;

    if (result instanceof Error) {
      responseData = result?.response?.data || [];
      updateUsers = responseData.filter((obj) => Object.keys(obj).length === 0).length > 0;
    } else {
      responseData = result;
      updateUsers = true;
    }

    let failedUsers = [];
    if (result instanceof Error) {
      failedUsers = users
        .map((user, index) => ({index, user}))
        .filter(({index}) => Object.keys(responseData[index] || {}).length);
    }

    if (confirm.current) {
      confirm.current({
        confirmed: true,
        failedUsers,
        responseData,
        isError: result instanceof Error,
        updateUsers
      });
    }

    setOpenState(false);
    setUsers([]);
  };

  const userViewComponent = useMemo(() => {
    if (users.length > 1) {
      return <MultipleUsersPreview users={users} />;
    } else if (users.length === 1) {
      return <UserPreview user={users[0]} />;
    }
  }, [users]);

  return (
    <Modal open={openState} onClose={handleClose}>
      <ModalContainer flexDirection="column">
        <Loader absolute={true} loading={loading} />
        <ModalHeader onClose={handleClose} title="Confirm User Details" />
        {userViewComponent}
        <Footer width="100%" display="flex" justifyContent="flex-end">
          <SubmitBtn label="Submit" onClick={onSubmit} />
        </Footer>
      </ModalContainer>
    </Modal>
  );
});
