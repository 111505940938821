import AddIcon from '@mui/icons-material/Add';
import {Box, Button, IconButton, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {usersService} from 'api/services/users';
import {ReactComponent as DeleteIcon} from 'assets/icons/icon_delete.svg';
import {ReactComponent as ResendEmailIcon} from 'assets/icons/icon_resend_email.svg';
import {ReactComponent as SearchIcon} from 'assets/icons/icon_search.svg';
import {CustomInput} from 'components/CustomInput/CustomInput';
import {ColumnHeader} from 'components/DataTable/components/ColumnHeader/ColumnHeader';
import {DataTable} from 'components/DataTable/DataTable';
import {Loader} from 'components/Loader/Loader';
import {Pagination} from 'components/SearchResultsTable/Pagination';
import {enqueueSnackbar} from 'notistack';
import {useEffect, useMemo, useRef, useState} from 'react';
import {AdminItemStoreProvider} from 'stores/contexts/AdminItemStore';
import {useAdminItemStore} from 'stores/hooks/useAdminItemStore';
import {useAdminStore} from 'stores/hooks/useAdminStore';
import {useProfileStore} from 'stores/hooks/useProfileStore';

import styles from '../Admin.module.css';
import {RemoveDialog} from '../components/RemoveDialog/RemoveDialog';
import {TableCell} from '../components/Table/TableCell/TableCell';
import {AddUser} from './AddUser/AddUser';
import {ResendEmailDialog} from './ResendEmailDialog/ResendEmailDialog';

export const Users = () => (
  <AdminItemStoreProvider>
    <UsersContent />
  </AdminItemStoreProvider>
);

const UsersContent = () => {
  const [profile] = useProfileStore();
  const {update, setState, ...adminState} = useAdminItemStore();
  const {users, organizations} = useAdminStore();
  const addUser = useRef(null);
  const removeUser = useRef(null);
  const resendEmail = useRef(null);
  const theme = useTheme();
  const [rows, setRows] = useState([]);

  useEffect(() => {
    if (!users.loading && users.data) {
      setRows(
        users.data
          .map((user) => ({
            ...user,
            is_admin: user.is_admin || false
          }))
          .filter((user) => profile?.user?.id !== user.id)
      );
    }
  }, [users.loading, users.data, profile?.user]);

  useEffect(() => {
    setState({
      ...adminState,
      count: rows.length
    });
  }, [rows]);

  const getUserFullName = (user) => {
    const nameParts = [user?.first_name, user?.last_name];

    return nameParts.filter(Boolean).join(' ');
  };

  const requestDeleteModal = async (userId) => {
    const success = await removeUser.current.open([userId]);

    if (success) {
      let deletedUser;
      let result;
      setRows((prev) => {
        result = prev.slice();

        const index = result.findIndex((item) => item.id === userId);
        if (index !== -1) {
          const [user] = result.splice(index, 1);
          deletedUser = user;
        }

        return result;
      });
      users.update(result);

      if (deletedUser) {
        const config = {
          preventDuplicate: true,
          key: `remove-user-${userId}`,
          autoHideDuration: 2500,
          variant: 'success'
        };

        enqueueSnackbar(`User ${getUserFullName(deletedUser)}, successfully deleted.`, config);
      }
    }
  };

  const requestAddUserModal = async () => {
    const newUsers = await addUser.current.open();

    if (newUsers) {
      users.execute();
      organizations.execute();
    }
  };

  const requestResendEmailModal = async (userId) => {
    const result = await resendEmail.current.open(userId);
    if (result) {
      const config = {
        preventDuplicate: true,
        key: `resend-email-${userId}`,
        autoHideDuration: 2500,
        variant: 'success'
      };

      enqueueSnackbar('Email invitation sent.', config);
    }
  };

  const columns = useMemo(
    () => [
      {
        field: 'email',
        renderHeader: () => <ColumnHeader label="Email" />,
        disableColumnMenu: true,
        minWidth: 250,
        flex: 1,
        renderCell: ({value}) => <TableCell label={value} color="secondary" />
      },
      {
        field: 'name',
        renderHeader: () => <ColumnHeader label="Name" />,
        disableColumnMenu: true,
        minWidth: 150,
        valueGetter: ({row}) => getUserFullName(row),
        renderCell: ({value}) => <TableCell label={value} color="secondary" />
      },
      {
        field: 'organization',
        renderHeader: () => <ColumnHeader label="Organization" />,
        disableColumnMenu: true,
        minWidth: 220,
        valueGetter: ({row}) => row?.organization?.name,
        renderCell: ({value}) => <TableCell label={value || '-'} color="secondary" />
      },
      {
        field: 'is_admin',
        renderHeader: () => <ColumnHeader label="Role" />,
        disableColumnMenu: true,
        minWidth: 100,
        sortable: false,
        renderCell: ({value: is_admin}) => (
          <TableCell
            label={is_admin ? 'Admin' : 'User'}
            color="secondary"
            filled={true}
            style={{backgroundColor: is_admin ? theme.palette.red.alpha10 : theme.palette.secondary.alpha10}}
          />
        )
      },
      {
        field: 'actions',
        renderHeader: () => <ColumnHeader label="Actions" justifyContent="center" />,
        disableColumnMenu: true,
        minWidth: 100,
        headerAlign: 'center',
        align: 'center',
        sortable: false,
        renderCell: ({row}) => (
          <Box display="flex" alignItems="center" style={{gap: 4}}>
            <IconButton
              onClick={() => {
                requestResendEmailModal(row.id);
              }}
            >
              <ResendEmailIcon color="secondary" />
            </IconButton>
            <IconButton
              onClick={() => {
                requestDeleteModal(row.id);
              }}
            >
              <DeleteIcon color="secondary" />
            </IconButton>
          </Box>
        )
      }
    ],
    []
  );

  const onPageSize = (pageSize) => {
    setState({
      ...adminState,
      pageSize,
      page: 0
    });
  };

  const onPage = (page) => {
    update('page', page);
  };

  const filteredRows = useMemo(
    () =>
      rows.filter((user) => {
        const currentSearchQuery = adminState.search.toLowerCase();
        const nameParts = [user?.first_name, user?.last_name].filter(Boolean).join(' ').toLowerCase();

        return nameParts.indexOf(currentSearchQuery) !== -1;
      }),
    [adminState.search, rows]
  );

  const searchQueryOnInput = (e) => {
    setState({
      ...adminState,
      search: e.target.value,
      page: 0
    });
  };

  const onSort = (sort) => {
    if (sort) {
      setState({
        ...adminState,
        ordering: sort,
        page: 0
      });
    }
  };

  return (
    <Box className={styles.adminContainer}>
      <Loader loading={users.loading} absolute={true} />
      <RemoveDialog
        ref={removeUser}
        title="Remove User"
        description="Are you sure you want to remove this user ?"
        submitBtnText="Remove"
        service={usersService.removeUser}
      />
      <ResendEmailDialog ref={resendEmail} />
      <AddUser ref={addUser} />
      <Box className={styles.tableHeader} display="flex" justifyContent="space-between" alignItems="center">
        <CustomInput
          inputClassName={styles.searchInput}
          value={adminState.search}
          autoComplete="one-time-code"
          className={styles.searchInputContainer}
          placeholder="Search Users..."
          onChange={searchQueryOnInput}
          InputProps={{startAdornment: <SearchIcon className={styles.searchIcon} style={{fill: theme.palette.secondary.main}} />}}
        />
        <Button
          className={styles.addBtn}
          variant="contained"
          type="submit"
          onClick={requestAddUserModal}
          sx={{background: theme.palette.red.main}}
        >
          <AddIcon className={styles.addBtnIcon} />
          <Typography className={styles.addBtnText}>Add User</Typography>
        </Button>
      </Box>
      <DataTable
        columns={columns}
        rows={filteredRows}
        autoHeight={true}
        dataGridProps={{
          getRowId: (row) => row.id,
          loading: false,
          sortingMode: 'client',
          onSortModelChange: (sortModel) => {
            onSort(sortModel?.[0]);
          },
          pagination: true,
          paginationMode: 'client',
          rowCount: adminState.count,
          page: adminState.page,
          hideFooterPagination: true,
          pageSize: adminState.pageSize,
          sortModel: adminState.ordering ? [adminState.ordering] : undefined
        }}
        dataGridStyles={{
          '& .MuiDataGrid-columnHeaders': {border: 'none'},
          '& .MuiDataGrid-row': {borderBottom: `1px solid ${theme.palette.gray.gray100}`}
        }}
        emptyOverlayProps={{text: 'There are currently no search results.'}}
      />
      {!users.loading && (
        <Pagination
          pagination={{
            pageSize: adminState.pageSize,
            page: adminState.page,
            count: filteredRows.length
          }}
          onPageSize={onPageSize}
          onPage={(page) => onPage(page - 1)}
        />
      )}
    </Box>
  );
};
