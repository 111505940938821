import {Grid} from '@mui/material';
import {useAdminStore} from 'stores/hooks/useAdminStore';

import {PreviewSection} from '../../../components/PreviewSection/PreviewSection';

export const UserPreview = ({user}) => {
  const {organizations} = useAdminStore();

  const getOrganizationName = (organizationId) => {
    if (!organizationId) {
      return '';
    }

    return organizations?.data?.find((organization) => organization.id === organizationId)?.name || '';
  };

  return (
    <Grid container spacing={4}>
      <PreviewSection label="First Name:" value={user.first_name} />
      <PreviewSection label="Last Name:" value={user.last_name} />
      <PreviewSection label="Email Address:" value={user.email} />
      <PreviewSection label="Organization: " value={getOrganizationName(user.organization_id)} />
      <PreviewSection label="Role:" value={user.is_staff ? 'Admin' : 'User'} />
    </Grid>
  );
};
