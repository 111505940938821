export const removeChildNodes = (ele, classPrefix, display, index = false) => {
  const successors = ele?.successors()?.targets();

  successors.forEach((el) => {
    removeChildNodes(el, classPrefix, display);
  });

  if (ele._private?.data?.id) {
    const id = ele._private?.data?.id.replaceAll(':', '-');
    const prevDivs = document.querySelectorAll(`.${classPrefix}-${id}`);

    if (prevDivs) {
      prevDivs.forEach((prevDiv) => prevDiv.remove());
    }
    if (index) {
      return;
    }

    const labels = document.querySelectorAll(`.node-label-${id}`);
    if (labels) {
      labels.forEach((label) => {
        label.parentElement.style.display = display === 'none' ? 'none' : 'block';
      });
    }
  }
};

export const closeTippy = (tippyRef) => (ele) => {
  [...tippyRef.current]
    .filter((badge) => badge._private?.data?.id === ele?._private.data.id)
    .forEach((badge) => {
      removeChildNodes(ele, 'canvas-popup-element');
      badge.badge?.destroy();
    });

  removeChildNodes(ele, 'canvas-popup-element');
  ele.badge.destroy();
  ele.badge = undefined;

  tippyRef.current = [...tippyRef.current].filter((badge) => badge._private?.data?.id !== ele?._private.data.id);
};

export const prepareNodes = (nodes) => {
  return nodes.map((nodeItem) => ({
    data: {
      id: nodeItem.entity.triton_id,
      label: nodeItem.entity.label,
      entityType: nodeItem.entity.entity_type
    },
    classes: 'l1'
  }));
};

export const prepareEdges = (edges) => {
  const uniqueEdges = edges.filter(
    (element, index, array) => array.findIndex((a) => a.source === element.source && a.target === element.target) === index
  );

  return uniqueEdges.map((x) => {
    // We'll need to know all sibling edges of each unique edge and create an
    // array from all their relation_types
    const groupEdges = edges.filter(({source, target}) => source === x.source && target === x.target);

    return {
      data: {
        id: x.id,
        source: x.source,
        target: x.target,
        relationships: groupEdges.map(({relation_type}) => relation_type)
      },
      classes: 'i'
    };
  });
};
