import styled from '@emotion/styled';
import {Box, Popover} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {useTheme} from '@mui/material/styles';
import cn from 'classnames';
import {usePostHog} from 'posthog-js/react';
import {useState} from 'react';

import {entitiesService} from '../../../../../api/services/entities';
import {ReactComponent as RightUpIcon} from '../../../../../assets/icons/icon_arrow_right_up.svg';
import {ReactComponent as GlobalFishingWatchIcon} from '../../../../../assets/icons/icon_global_fishing_watch.svg';
import {ReactComponent as SkylightIcon} from '../../../../../assets/icons/icon_skylight.svg';
import {useApi} from '../../../../../hooks/useApi';
import {captureUserGoToGFW, captureUserGoToSkylight} from '../../../../../posthog/events';
import {useProfileStore} from '../../../../../stores/hooks/useProfileStore';
import {Loader} from '../../../../Loader/Loader';
import styles from './MMSIComponent.module.css';

const StyledBenchmarkItem = styled.a({}, ({theme}) => ({
  color: theme.palette.secondary.main,
  '&:hover': {background: theme.palette.gray.lightgray}
}));

export const MMSIComponent = ({entityData, mmsiCode}) => {
  const posthog = usePostHog();
  const [profile] = useProfileStore();
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);
  const skylightData = useApi({
    service: entitiesService.getSkylightUrlByMMSI,
    immediate: true,
    payload: mmsiCode,
    disableErrorHandling: true
  });

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'mmsi-popover' : undefined;

  const onOpenGFW = () => {
    const {eventName, payload} = captureUserGoToGFW({
      triton_id: entityData.triton_id,
      mmsi_code: mmsiCode,
      user_email: profile.user.email
    });
    posthog?.capture(eventName, payload);
  };

  const onOpenSkylight = () => {
    const {eventName, payload} = captureUserGoToSkylight({
      triton_id: entityData.triton_id,
      mmsi_code: mmsiCode,
      user_email: profile.user.email
    });
    posthog?.capture(eventName, payload);
  };

  return (
    <>
      <Loader absolute={true} loading={skylightData.loading} />
      <IconButton onClick={handleClick} sx={{backgroundColor: theme.palette.gray.gray50}} className={styles.mmsiButton}>
        <RightUpIcon style={{fill: theme.palette.secondary.secondary200}} className={styles.mmsiIcon} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
      >
        <Box display="flex" flexDirection="column" className={styles.popoverContent}>
          <StyledBenchmarkItem
            className={styles.benchmarkItem}
            theme={theme}
            href={`https://globalfishingwatch.org/map/vessel-search?qry=${mmsiCode}`}
            target="_blank"
            onMouseDown={onOpenGFW}
          >
            <GlobalFishingWatchIcon />
            <span className={styles.benchmarkItemText}>Find vessel in Global Fishing Watch</span>
          </StyledBenchmarkItem>
          <StyledBenchmarkItem
            theme={theme}
            className={cn(styles.benchmarkItem, skylightData.status !== 200 && styles.disabledBenchmarkItem)}
            href={skylightData.data?.vessel_url ?? '#'}
            target="_blank"
            onMouseDown={onOpenSkylight}
          >
            <SkylightIcon />
            <span className={styles.benchmarkItemText}>Find vessel in Skylight</span>
          </StyledBenchmarkItem>
        </Box>
      </Popover>
    </>
  );
};
