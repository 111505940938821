import {Box, Typography} from '@mui/material';
import cn from 'classnames';

import styles from './SelectOption.module.css';

export const SelectOption = ({label, className, optionOnClick, style}) => (
  <Box display="flex" alignItems="center" className={cn(styles.option, className)} onClick={optionOnClick} style={style}>
    {typeof label === 'string' ? <Typography className={styles.label}>{label}</Typography> : label}
  </Box>
);
