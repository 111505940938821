import Box from '@mui/material/Box';
import {useWindowDimensions} from 'hooks/useWindowDimensions';

import {LargeImageLoader} from '../LargeImageLoader/LargeImageLoader';
import styles from './Background.module.css';

export const Background = ({backgroundImage, sx, children}) => {
  const windowDimensions = useWindowDimensions();

  return (
    <Box className={styles.backgroundBox} sx={[{minHeight: `${windowDimensions.height}px`}, ...(Array.isArray(sx) ? sx : [sx])]}>
      <LargeImageLoader
        className={styles.imageWrapper}
        imgClassName={styles.image}
        imgSrc={backgroundImage}
        alt="background"
        style={{minHeight: `${windowDimensions.height}px`}}
      />
      {children}
    </Box>
  );
};
