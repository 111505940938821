import {Box, Grid, Stack, Tooltip, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import cn from 'classnames';
import {CollapseList} from 'components/CollapseList/CollapseList';
import {IUUStatus} from 'components/IUUStatus/IUUStatus';
import {StatusChip} from 'components/StatusChip/StatusChip';
import {SANCTION_STATUS_DCT} from 'constants/entities';
import {useEffect, useState} from 'react';
import {getCountryNameByCountryCode, prepareFilterKeyToLabelAndCapitalizeAllWords} from 'utils/helpers';
import {formatDate} from 'utils/time';

import {MMSIComponent} from './components/MMSIComponent/MMSIComponent';
import {Connections} from './Connections/Connections';
import styles from './DetailsPanelBody.module.css';

const renderSanctionStatusCell = (value) => {
  if (!value) {
    return 'N/A';
  }
  const status = value ? SANCTION_STATUS_DCT[value] : 'default';
  return <StatusChip status={status} label={prepareFilterKeyToLabelAndCapitalizeAllWords(value)} />;
};

const renderList = (values) => (
  <CollapseList
    options={values}
    maxSize={2}
    sx={{marginTop: '-0.5rem'}}
    listItemSX={{
      paddingLeft: '0px',
      paddingRight: '0px'
    }}
    listItemClassName={styles.listItem}
  />
);

const renderLongNames = (value) => {
  if (!value) {
    return 'N/A';
  }
  value = Array.isArray(value) ? value : [value];

  return (
    <>
      {renderList(
        value.map((name) => (
          <Tooltip title={name} key={name}>
            <span className={styles.bodyFieldValue}>{name}</span>
          </Tooltip>
        ))
      )}
    </>
  );
};

const renderStringValue = (value) => {
  if (value) {
    return value;
  } else {
    return <span>N/A</span>;
  }
};

const arrayFieldCheck = (value, renderArrayFunction) => {
  if (typeof value === 'string') {
    return renderStringValue(value);
  } else if (typeof value === 'number') {
    return renderStringValue(value.toString());
  } else {
    return renderArrayFunction(value);
  }
};

const renderLinks = (value) => {
  let links = [];
  if (typeof value === 'string') {
    links.push(value);
  } else {
    links = value;
  }

  return (
    <>
      {renderList(
        links.map((link) => (
          <Tooltip title={link} key={link}>
            <a href={link} target="_blank" className={styles.bodyFieldValue} rel="noreferrer">
              {link}
            </a>
          </Tooltip>
        ))
      )}
    </>
  );
};

const renderCountry = (countries) => {
  const countryCode = countries?.[0];

  return getCountryNameByCountryCode(countryCode) || 'N/A';
};

const fieldsRenderMap = {
  iuu_status: (value, entity_type) => <IUUStatus iuuStatus={value} entity_type={entity_type} empty={false} />,
  sanction_status: renderSanctionStatusCell,
  fleet_tags: (value) =>
    arrayFieldCheck(value, (value) => {
      value = value.map(prepareFilterKeyToLabelAndCapitalizeAllWords);
      return renderList(value);
    }),
  callsigns: (value) =>
    arrayFieldCheck(value, (value) => {
      value = value.map((item) => item.toUpperCase());
      return renderList(value);
    }),
  birthDates: (value) => arrayFieldCheck(value, (value) => renderList(value.map(formatDate))),
  incorporationDates: (value) => arrayFieldCheck(value, (value) => renderList(value.map(formatDate))),
  informationDates: (value) => arrayFieldCheck(value, (value) => renderList(value.map(formatDate))),
  emails: renderLongNames,
  formerNames: renderLongNames,
  registrations: renderLongNames,
  websites: renderLinks,
  countries: renderCountry,
  sources: (value) => <span className={cn(styles.bodyFieldValue, styles.sources)}>{value.join(', ')}</span>,
  mmsiIds: (value, _, entityData) => {
    const mmsiCode = value?.[0];

    return mmsiCode ? (
      <>
        <Typography variant="body1" color="secondary" component="div" className={styles.fieldValue}>
          {mmsiCode}
        </Typography>
        <MMSIComponent mmsiCode={mmsiCode} skylightCode="" entityData={entityData} />
      </>
    ) : (
      <span>N/A</span>
    );
  }
};

export const DetailsPanelBody = ({fields, data, onMemberId}) => {
  const theme = useTheme();
  const [emptyFields, setEmptyFields] = useState([]);

  useEffect(() => {
    const breakPoints = [
      [0, 1],
      [600, 2],
      [900, 3],
      [1200, 4]
    ];

    const handler = () => {
      const innerWidth = window.innerWidth;

      const breakPoint = breakPoints
        .slice()
        .reverse()
        .find(([width]) => innerWidth > width);

      if (!breakPoint) {
        setEmptyFields([]);
      }

      const mod = fields.length % breakPoint[1];
      const arr = new Array(breakPoint[1] - (mod === 0 ? breakPoint[1] : mod));
      setEmptyFields(arr.fill(null));
    };
    window.addEventListener('resize', handler);
    handler();

    return () => {
      window.removeEventListener('resize', handler);
    };
  }, [fields]);

  const renderValue = (key, value, data) => {
    if (!value || value.length === 0) {
      return <span>N/A</span>;
    } else if (typeof value === 'string' || typeof value === 'number') {
      value = value.toString();
      return fieldsRenderMap[key] ? fieldsRenderMap[key](value, data.entity_type, data) : value;
    } else if (Array.isArray(value) && value.length > 0) {
      return fieldsRenderMap[key] ? fieldsRenderMap[key](value, data.entity_type, data) : renderList(value);
    }
  };

  return (
    <Grid container justifyContent="center">
      <Grid container spacing={5} className={styles.bodyContainer} style={{borderColor: theme.palette.gray.gray100}}>
        {fields.map(({key, label}) => {
          if (key === 'imo_id' && data[key] === '-1') {
            data[key] = '';
          }

          const fieldValue = renderValue(key, data[key], data);
          return (
            <Grid item className={styles.cell} xs={12} sm={6} md={4} lg={3} key={key}>
              <Stack>
                <Typography variant="body1" color="secondary" component="div" className={styles.label}>
                  {label}
                </Typography>
                {typeof fieldValue === 'string' ? (
                  <Typography variant="body1" color="secondary" component="div" className={styles.fieldValue}>
                    {fieldValue}
                  </Typography>
                ) : (
                  <Box className={styles.fieldValue}>{fieldValue}</Box>
                )}
              </Stack>
            </Grid>
          );
        })}
        {emptyFields.map((field, index) => (
          <Grid item className={styles.cell} xs={12} sm={6} md={4} lg={3} key={index} />
        ))}
      </Grid>
      <Connections onMemberId={onMemberId} tritonId={data?.triton_id} />
    </Grid>
  );
};
