class Node {
  parents = [];
  children = [];
  linkTo;

  constructor(id, nodeData) {
    this.id = id;
    this.nodeData = nodeData;
  }

  addChild(node) {
    this.children.push(node);
  }

  setParent(node) {
    this.parents = [node, ...this.parents.filter((parentNode) => parentNode.id !== node.id)];
  }
}

export class NetworkGraphManager {
  nodes = {};
  rawNodes = [];
  rawEdges = [];

  constructor(nodes, edges) {
    this.rawNodes = nodes;
    this.rawEdges = edges;

    this.initTree(nodes, edges);
  }

  initTree(nodes, edges) {
    const currentNodes = {};
    nodes.forEach((node) => {
      currentNodes[node.entity.triton_id] = new Node(node.entity.triton_id, {
        ...node.entity,
        id: node.entity.triton_id,
        entityType: node.entity.entity_type
      });
    });

    edges.forEach((edge) => {
      const childId = edge.target;
      const parentId = edge.source;

      const parentNode = currentNodes[parentId];
      const childNode = currentNodes[childId];

      parentNode.addChild(childNode);
      childNode.setParent(parentNode);
    });

    this.nodes = currentNodes;
  }

  getOpenedNodes(nodes) {
    const result = {};
    nodes.forEach((node) => {
      const currentNode = node.linkTo ?? node;
      result[currentNode.id] = currentNode;
    });

    return Object.values(result);
  }

  descendants(nodeId) {
    const getDescendants = (node) => {
      return [...this.getOpenedNodes(node.children), ...node.children.flatMap(getDescendants)];
    };

    return getDescendants(this.nodes[nodeId]).filter(Boolean);
  }

  ancestors(nodeId) {
    const getAncestors = (node) => {
      return [...this.getOpenedNodes(node.parents), ...node.parents.flatMap(getAncestors)];
    };

    return getAncestors(this.nodes[nodeId]).filter(Boolean);
  }

  getNodesEntitiesData() {
    const checkedNodes = new Set();

    return Object.values(this.nodes)
      .map((node) => {
        const currentNode = node.linkTo ?? node;
        if (checkedNodes.has(currentNode.id)) {
          return;
        }

        checkedNodes.add(currentNode.id);
        return currentNode;
      })
      .filter(Boolean)
      .map((node) => node.nodeData);
  }

  getRoot() {
    return Object.values(this.nodes).find((node) => {
      return !node.parents.length;
    });
  }

  getNode(nodeId) {
    return this.nodes[nodeId];
  }

  getPathsToAncestor(from, to) {
    const goToAncestor = (currentNode, ancestor, path = []) => {
      const parentsIds = currentNode.parents.map((parent) => parent.id);
      if (parentsIds.includes(ancestor.id)) {
        return [...path, ancestor.id];
      }

      return currentNode.parents.flatMap((parent) => goToAncestor(parent, ancestor, [...path, parent.id]));
    };

    return goToAncestor(from, to, [from.id]);
  }
}
