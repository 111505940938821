/* eslint-disable max-len */
import {Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {NavLink} from 'react-router-dom';

import styles from './MethodologySection/MethodologySection.module.css';

const MethodologyText = ({children}) => {
  const theme = useTheme();

  return (
    <Typography style={{color: theme.palette.white.main}} className={styles.description}>
      {children}
    </Typography>
  );
};

const MethodologyLink = ({to, children}) => {
  const theme = useTheme();

  return (
    <NavLink style={{color: theme.palette.white.main}} className={styles.descriptionLink} target="_blank" to={to}>
      {children}
    </NavLink>
  );
};

export const methodologySections = [
  {
    key: 'The Challenge',
    title: 'The Challenge',
    description: [
      'Despite growing recognition on the need for more transparency in the  world’s industrial fishing fleets, it is still difficult to connect the activities of a fishing boat  with the onshore companies that profit from them.',
      'This lack of easily accessible information enables owners to obscure their connections  to illicit activity and mask their foreign investments. To uncover this information, investigators must compile data from multiple sources and often across several jurisdictions, which can be a complex and time-consuming process and is particularly difficult for under-resourced fisheries enforcement agencies, civil society organizations, and journalists.',
      'Through Triton, C4ADS aims to address this challenge by leveraging publicly available information to reveal the ownership structures behind the world\'s industrial fishing fleet.'
    ]
  },
  {
    key: 'Method Overview',
    title: 'Method Overview',
    description: [
      () => (
        <MethodologyText>
          The goal of Triton is to map the full ownership networks for the roughly 38,000 vessels in the industrial fishing fleet.
          C4ADS first sources identifying information on all active fishing and refrigerated cargo vessels and their registered
          and group owners from
          {' '}
          <MethodologyLink to="https://www.spglobal.com/">S&P Global - IHS Markit.</MethodologyLink>
        </MethodologyText>
      ),
      'If the vessel’s registered or group owner is a company, analysts use national corporate registries to source additional details about the company and identify its leadership and shareholders. If one of the shareholders is a company, analysts aim to map additional layers of ownership using corporate registries until all company shareholders are individual people, rather than companies, or until additional information is unavailable. Where possible, analysts then use identifying information about the companies and shareholders, such as national ID numbers, to resolve entities and connect multiple network charts.',
      'Over time, Triton will be updated with additional information and layers of ownership. When ownership information is not included in Triton, it may indicate the information is not currently available through existing sources or that analysts have yet to complete the chart in its entirety. If you would like to enquire about the status of updates or to request that analysts prioritize updates for a particular fleet, please contact the C4ADS team by emailing contact@triton.fish to discuss your request.',
      'Several additional data sources are used to further enhance the data in Triton. Vessel gear type and fishing authorization is sourced from twelve Regional Fishery Management Organization (RFMO) authorized vessel lists. Fishing gear type is categorized into five main types: purse seine, gillnets, longline, squid jigger, and trawler. When gear type is unknown or there appears to be multiple gear types listed, the vessel is classified as “Unknown”.  One additional vessel type, “reefer”, or refrigerated cargo ship, is sourced from the S&P Global - IHS Markit vessel categories.',
      () => (
        <MethodologyText>
          Illegal, Unreported, and Unregulated (IUU) fishing status is sourced from Trygg Mat Tracking’s
          {' '}
          <MethodologyLink to="https://iuu-vessels.org/">Combined IUU Vessel List</MethodologyLink>
          , which identifies if a vessel
          is currently or was previously IUU-listed by an RFMO. Sanctions information for vessels, individuals, and companies is
          sourced from
          <MethodologyLink to="https://sanctionsexplorer.org/">Sanctions Explorer</MethodologyLink>
          , which includes sanctions from
          OFAC, the UN, EU, UK, BIS, and Japan.
        </MethodologyText>
      ),
      'Triton’s corporate network analysis is built primarily on official documentation and data from national corporate registries and trusted third-party corporate record aggregators, and secondarily on other credible public data, such as credit reports.'
    ]
  },
  {
    key: 'Data Usage and Updates',
    title: 'Data Usage and Updates',
    description: [
      'In addition to adding new information to Triton on an ongoing basis, existing information in Triton is scheduled to be updated every six months for networks that have changes in their first level of ownership or have new vessels in their fleet. Information on a specific vessel or fleet may be changed ahead of schedule at the request of users and at the discretion of C4ADS. Users may email C4ADS at contact@triton.fish to submit a request.',
      'To ensure that the data in Triton is used effectively to improve transparency and accountability in the fishing sector, C4ADS grants access to Triton to users that have been vetted by C4ADS. On the entity page, users can download CSV files that contain identifying information for the target entity and its connected network. Ownership information is available for free to all Triton users, but users should cite “Triton, a fisheries transparency platform by C4ADS” for any information used in public reporting.',
      'C4ADS is dedicated to providing the most accurate information available on the vessels, fleets, and companies included within Triton. Although the information and data contained in Triton has been produced with the reasonable efforts of C4ADS and has been compiled from sources believed to be reliable and accurate, no warranty, expressed or implied, is made regarding adequacy, completeness, legality, reliability, accuracy or usefulness of any of the information. Additionally, all of the information contained herein is provided on an ‘as is’ basis. Users may occasionally find that official documents from different sources contain contradictory information about companies or other entities. If this happens, you can alert the C4ADS team by emailing contact@triton.fish. Please include “Conflicting Information” in the subject line of your email so analysts can prioritize reviewing the information you have found.'
    ]
  },
  {
    key: 'Analytical Support and Training Requests',
    title: 'Analytical Support and Training Requests',
    description: [
      'C4ADS is pleased to extend adhoc analytical support and bespoke investigations to users on a case-by-case basis. To do so, please email contact@triton.fish, and describe the entities of interest, reason for request, potential due date, and a brief description of the analysis requested. If C4ADS is able to assist, a follow-up meeting may be scheduled to gather more information and discuss details about the final deliverable.',
      'Although C4ADS is committed to helping partners with their investigations, there may not be the capacity to fulfill all requests or all aspects of a request.',
      'C4ADS is also available to provide bespoke training for users on best practices for using Triton as well as customized curriculums on using publicly available information to enhance counter-IUU fishing efforts. Please email contact@triton.fish for more information.'
    ]
  },
  {
    key: 'Defining Ultimate Beneficial Ownership',
    title: 'Defining Ultimate Beneficial Ownership',
    description: [
      'Ultimate beneficial ownership generally refers to the natural persons who own or control a fishing vessel. In the absence of beneficial ownership registries that clearly state a vessel\'s ultimate beneficial owners, C4ADS relies on corporate registry data to determine a vessel\'s majority ownership via shareholdership, up to natural persons whenever possible. In doing so, C4ADS aims to get as close to ultimate beneficial ownership as possible using only publicly available data.',
      'Note that in some cases, ultimate beneficial information cannot be determined using publicly available data. In other cases, the individuals listed on company or vessel paperwork are meant to cover for the true owners, who remain anonymous. In these cases, private information, such as financial data, is usually needed to identify a vessel or company\'s real ultimate beneficial owners.',
      () => (
        <MethodologyText>
          For additional information on the challenges with tracking ultimate beneficial ownership in the fishing industry, see
          the 2023 C4ADS report
          {' '}
          <MethodologyLink to="https://c4ads.org/issue-briefs/sea-shells/">
            Seashells: Developing Beneficial Ownership Transparency in the Fishing Industry.
          </MethodologyLink>
        </MethodologyText>
      )
    ]
  },
  {
    key: 'Other Assumptions and Limitations',
    title: 'Other Assumptions and Limitations',
    description: [
      'Triton network charts display information vertically, based on the selected entity’s direct relationships with other entities. If a person is selected, the network chart will display the company and vessels directly connected to that person but will not display the other people who are also connected to those same entities. To display those relationships, users should select the company associated with the individual and then view all entities connected to that company.',
      'Triton does not include past ownership data. As new information is gathered, the old information is replaced.',
      'As new information is continuously being added to Triton, missing ownership information may indicate the information is not currently available through existing sources or that analysts have yet to complete the chart in its entirety.',
      'On a case by case basis we made the determination that some vessels were directly registered to an individual as opposed to a company. This judgment was made based on naming conventions and an absence of results from corporate registries searches.'
    ]
  }
];
