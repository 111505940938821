import {useResizeEvent} from 'hooks/useResizeEvent';
import {useEffect, useState} from 'react';

export function useWindowDimensions() {
  const resize = useResizeEvent();
  const [width, setWidth] = useState(null);
  const [height, setHeight] = useState(null);

  useEffect(() => {
    const updateDimensions = () => {
      setWidth(window.innerWidth);
      setHeight(window.innerHeight);
    };

    window.addEventListener('resize', updateDimensions);
    setTimeout(() => updateDimensions(), 1);

    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  return {
    width,
    height,
    resize
  };
}
