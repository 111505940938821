import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Accordion, AccordionDetails, AccordionSummary, Typography} from '@mui/material';
import {useMemo, useState} from 'react';

import {ErrorObj} from './ErrorObj/ErrorObj';
import styles from './FilesErrors.module.css';

export const FileObj = ({fileName, errors}) => {
  const [expanded, setExpanded] = useState(false);
  /*
   errors -> [ {currentValue, path, pathErrorMessages} ]}]
  */

  const handleChange = (e, isExpanded) => {
    setExpanded(isExpanded);
  };

  const accordionTitle = useMemo(() => {
    const title = `File: ${fileName}, contains ${errors.length} invalid users object${errors.length > 1 ? 's' : ''}.`;

    return title;
  }, [fileName, errors]);

  return (
    <Accordion expanded={expanded} onChange={handleChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography color="secondary" className={styles.fileTitle}>
          {accordionTitle}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        {errors
          .sort((a, b) => a.index - b.index)
          .map((error, index) => (
            <ErrorObj error={error} key={index} />
          ))}
      </AccordionDetails>
    </Accordion>
  );
};
