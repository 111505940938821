export const captureUserGoToSkylight = ({triton_id, mmsi_code, user_email}) => {
  return {
    eventName: 'Navigate to Skylight',
    payload: {triton_id, mmsi_code, user_email}
  };
};

export const captureUserGoToGFW = ({triton_id, mmsi_code, user_email}) => {
  return {
    eventName: 'Navigate to GFW',
    payload: {triton_id, mmsi_code, user_email}
  };
};
