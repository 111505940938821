import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import {Modal} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import {ReactComponent as SettingsIcon} from 'assets/icons/icon_settings_solid.svg';
import {SEARCH_FILTERS} from 'constants/entities';
import {useMemo} from 'react';
import {camelToTitle, prepareFilterKeyToLabelAndCapitalizeAllWords} from 'utils/helpers';

import styles from './QuickSearchFilters.module.css';
import {Section} from './Section';

export const QuickSearchFilters = ({open, onClose, filters, disabled}) => {
  const theme = useTheme();

  const reset = () => {
    filters.reset();
  };

  const onChange = (filterType) => {
    const values = {...SEARCH_FILTERS[filterType]};
    Object.keys(values).forEach((key) => {
      values[key] = false;
    });

    return (options) => {
      const clonedValues = {...values};
      options.forEach((option) => {
        clonedValues[option] = true;
      });
      filters.update(filterType, clonedValues);
    };
  };

  const getValue = (filterType) =>
    Object.entries(filters.values[filterType])
      .filter(([, value]) => value)
      .map(([key]) => key);

  const getOptions = (filterType) => Object.keys(SEARCH_FILTERS[filterType]);

  const vesselSelected = useMemo(() => {
    return filters.values.typeFilters.vessel;
  }, [filters]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box className={styles.container} sx={{backgroundColor: theme.palette.white.main}}>
        <Grid
          className={styles.header}
          wrap="nowrap"
          container
          justifyContent="space-between"
          alignItems="center"
          sx={{borderBottom: `1px solid ${theme.palette.secondary.alpha10}`}}
        >
          <Grid container alignItems="center">
            <SettingsIcon className={styles.titleIcon} style={{fill: theme.palette.secondary.main}} />
            <Typography component="h5" color="secondary.main" className={styles.title}>
              Filters
            </Typography>
          </Grid>
          <IconButton onClick={onClose}>
            <CloseRoundedIcon color="secondary.main" />
          </IconButton>
        </Grid>
        <Box className={styles.body}>
          <Section
            title="Entity Type"
            onChange={onChange('typeFilters')}
            value={getValue('typeFilters')}
            options={getOptions('typeFilters')}
            disabled={disabled}
            getLabel={(option) => <RenderLabel formatFunction={camelToTitle} option={option} />}
          />
          <Section
            title="IUU Status"
            onChange={onChange('statusFilters')}
            value={getValue('statusFilters')}
            options={getOptions('statusFilters')}
            disabled={disabled || !vesselSelected}
            getLabel={(option) => <RenderIUUStatus option={option} />}
          />
          <Section
            title="Sanction Status"
            onChange={onChange('sanctionFilters')}
            value={getValue('sanctionFilters')}
            options={getOptions('sanctionFilters')}
            disabled={disabled || !vesselSelected}
            getLabel={(option) => <RenderLabel formatFunction={prepareFilterKeyToLabelAndCapitalizeAllWords} option={option} />}
          />
          <Section
            title="Fleet Tags"
            onChange={onChange('fleetFilters')}
            value={getValue('fleetFilters')}
            options={getOptions('fleetFilters')}
            disabled={disabled || !vesselSelected}
            getLabel={(option) => <RenderLabel formatFunction={prepareFilterKeyToLabelAndCapitalizeAllWords} option={option} />}
          />
        </Box>
        <Box className={styles.footer}>
          <Grid container wrap="nowrap" className={styles.innerFooter}>
            <Button fullWidth variant="text" type="submit" onClick={reset}>
              Reset
            </Button>
            <Button fullWidth variant="contained" type="submit" onClick={onClose} sx={{background: theme.palette.red.main}}>
              Apply
            </Button>
          </Grid>
        </Box>
      </Box>
    </Modal>
  );
};

const RenderLabel = ({formatFunction, option}) => <Typography sx={{fontSize: '16px'}}>{formatFunction(option)}</Typography>;

const RenderIUUStatus = ({option}) => {
  return <Typography sx={{fontSize: '16px'}}>{camelToTitle(option)}</Typography>;
};
