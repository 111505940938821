import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import {useTheme} from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import cn from 'classnames';

import styles from './EntityFilterCheckbox.module.css';

export const FilterCheckbox = ({label, checked, onChange, disabled, children, labelSx, className}) => {
  const theme = useTheme();

  let customLabelSx = {fontWeight: 600};
  if (labelSx) {
    customLabelSx = {...labelSx};
  }

  return (
    <FormGroup className={className}>
      <FormControlLabel
        label={label}
        sx={customLabelSx}
        disabled={disabled}
        control={
          <>
            <Checkbox
              sx={{
                width: '32px',
                height: '32px',
                color: theme.palette.gray.gray200,
                '&.Mui-checked': {color: theme.palette.red.main}
              }}
              checked={Boolean(checked)}
              onChange={(event) => {
                event.target.blur();
                onChange(event.target.checked);
              }}
              disabled={disabled}
            />
            {children}
          </>
        }
      />
    </FormGroup>
  );
};

export const AllCheckbox = ({labelClassName, checked, onChange, disabled}) => (
  <FilterCheckbox
    label={<Typography className={cn(labelClassName, disabled && styles.disabledLabel)}>Select All</Typography>}
    checked={checked}
    onChange={onChange}
    disabled={disabled}
  />
);
