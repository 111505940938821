import {createContext} from 'react';

class Subscriber {
  constructor(onReceive) {
    this.mute = true;
    this.push = onReceive;
  }

  startListen() {
    this.mute = false;
  }

  stopListen() {
    this.mute = true;
  }
}

class HttpErrorLighthouse {
  subscribers = {};
  state = {
    httpStatus: 200,
    message: null
  };

  listen(onReceive) {
    const countOfSubscribers = Object.keys(this.subscribers).length;
    const subscriberId = String(countOfSubscribers);

    const subscriber = new Subscriber(onReceive);
    subscriber.startListen();
    this.subscribers[subscriberId] = subscriber;

    return [
      subscriber,
      () => {
        /* stop listen function */
        subscriber.stopListen();
        delete this.subscribers[subscriberId];
      }
    ];
  }

  updateState(httpStatus, message) {
    this.state = {httpStatus, message};
    Object.entries(this.subscribers).map((entry) => {
      const subscriber = entry[1];

      if (!subscriber.mute) {
        subscriber.push(httpStatus, message);
      }
    });
  }

  manageHttpResponse(error) {
    switch (error?.response?.status) {
    case undefined:
      this.updateState(504, 'You are offline');
      break;
    case 401:
      this.updateState(401, 'You session is expired please click ');
      break;
    case 403:
      this.updateState(403, 'You do not have permission to perform this action.');
      break;
    default:
      this.updateState(error.response.status, error.response.data.detail || 'Server error.');
      break;
    }
  }
}

export const httpErrorLighthouse = new HttpErrorLighthouse();
export const HttpErrorContext = createContext({httpErrorLighthouse});

export const HttpErrorStore = (props) => {
  const {children} = props;

  return <HttpErrorContext.Provider value={{httpErrorLighthouse}}>{children}</HttpErrorContext.Provider>;
};
