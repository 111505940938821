import {Typography} from '@mui/material';
import cn from 'classnames';

import styles from './TableCell.module.css';

export const TableCell = ({label, className, filled = false, ...props}) => (
  <Typography {...props} title={label} className={cn(styles.cellText, filled && styles.filled, className)}>
    {label}
  </Typography>
);
