import MenuIcon from '@mui/icons-material/Menu';
import {AppBar, Box, Drawer, Toolbar} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import {useTheme} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import logoImage from 'assets/logos/logo_triton_white.png';
import cn from 'classnames';
import {useEffect, useState} from 'react';
import {useLayoutStore} from 'stores/hooks/useLayoutStore';
import {useProfileStore} from 'stores/hooks/useProfileStore';

import {HideOnScroll} from '../../HideOnScroll/HideOnScroll';
import styles from './Header.module.css';
import {NavItems} from './NavItems/NavItems';

export const Header = () => {
  const transparent = false;
  const [profile] = useProfileStore();
  const {header} = useLayoutStore();
  const [disableHideOnScroll, setDisableHideOnScroll] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [appBarPosition, setAppBarPosition] = useState('fixed');
  const [appBarColor, setAppBarColor] = useState('secondary');
  const [appBarElevation, setAppBarElevation] = useState(4);
  const theme = useTheme();
  const isLargeViewport = useMediaQuery(theme.breakpoints.up('lg'));
  const isSmallViewport = useMediaQuery(theme.breakpoints.between('xs', 'lg'));

  useEffect(() => {
    if (isLargeViewport) {
      setDrawerOpen(false);
    }
  }, [isLargeViewport]);

  useEffect(() => {
    if (transparent) {
      setDisableHideOnScroll(false);
      setAppBarPosition('fixed');

      if (!drawerOpen) {
        setAppBarColor('transparent');
        setAppBarElevation(0);
      } else {
        setAppBarColor('secondary');
        setAppBarElevation(4);
      }
    } else if (!transparent) {
      setDisableHideOnScroll(true);
      setAppBarColor('secondary');

      if (!drawerOpen) {
        setAppBarPosition('static');
      } else {
        setAppBarPosition('fixed');
      }
    }
  }, [transparent, drawerOpen]);

  const itemOnClick = () => {
    setDrawerOpen(false);
  };

  return (
    <Box>
      <HideOnScroll disable={disableHideOnScroll}>
        <AppBar
          position={appBarPosition}
          color={appBarColor}
          elevation={appBarElevation}
          className={styles.appBar}
          sx={{zIndex: theme.zIndex.drawer + 1}}
        >
          <Toolbar className={styles.toolbar}>
            <Box display="flex" flexGrow={0}>
              <a href="/">
                <IconButton disableRipple sx={{padding: '0px'}}>
                  <img src={logoImage} height={64} alt="logo-triton-white" />
                </IconButton>
              </a>
            </Box>
            {header}
            {!!isLargeViewport && (
              <Box display="flex" flexGrow={1} className={cn(styles.navItems, !profile.user && styles.anonymousNavItems)}>
                <NavItems />
              </Box>
            )}
            {!!isSmallViewport && (
              <IconButton className={styles.menuIconBtn} color="white" edge="start" onClick={() => setDrawerOpen(!drawerOpen)}>
                <MenuIcon />
              </IconButton>
            )}
          </Toolbar>
        </AppBar>
      </HideOnScroll>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
          ModalProps={{keepMounted: true}}
          sx={{
            '.MuiPaper-root': {
              backgroundColor: theme.palette.secondary.main,
              marginTop: '64px',
              paddingTop: '0.5rem'
            }
          }}
        >
          <NavItems itemOnClick={itemOnClick} />
        </Drawer>
      </Box>
    </Box>
  );
};
