import {Box} from '@mui/material';
import cn from 'classnames';
import {usePostHog} from 'posthog-js/react';
import {useEffect} from 'react';
import {Outlet, useLocation, useNavigate} from 'react-router';
import {LayoutProvider} from 'stores/contexts/LayoutStore';
import {useLayoutStore} from 'stores/hooks/useLayoutStore';
import {useProfileStore} from 'stores/hooks/useProfileStore';

import {Footer} from './Footer/Footer';
import {Header} from './Header/Header';
import styles from './Layout.module.css';

export const Layout = () => {
  return (
    <LayoutProvider>
      <LayoutContent />
    </LayoutProvider>
  );
};

const LayoutContent = () => {
  const posthog = usePostHog();
  const {footer, layoutClassName} = useLayoutStore();
  const location = useLocation();
  const navigate = useNavigate();
  const [profile] = useProfileStore();

  useEffect(() => {
    if (profile?.user) {
      posthog?.identify(profile.user.email, {user_email: profile.user.email});
    }
  }, [profile?.user?.email]);

  useEffect(() => {
    if (location?.state?.scrollToTop) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });
      navigate(`${location.pathname}${location.search}`, {replace: true});
    }
  }, [location]);

  return (
    <Box className={cn(styles.layout, layoutClassName)}>
      <Header />
      <Box className={cn(styles.body, 'layoutBody')}>
        <Outlet />
      </Box>
      {!!footer && <Footer />}
    </Box>
  );
};
