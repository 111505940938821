import {loggedIn} from 'api/auth';
import {usersService} from 'api/services/users';
import {useApi} from 'hooks/useApi';
import {cloneElement, useEffect} from 'react';
import {useLocation} from 'react-router';
import {Navigate} from 'react-router-dom';
import {useProfileStore} from 'stores/hooks/useProfileStore';

import {REDIRECT_URL} from '../../constants/sessionStorage';

export const ProtectedRoute = ({routeType, children}) => {
  const [profile, userProfileStoreDispatch] = useProfileStore();
  const profileApi = useApi({
    service: usersService.profile,
    immediate: false
  });
  const isLoggedIn = loggedIn();
  const location = useLocation();

  useEffect(() => {
    if (isLoggedIn && !profileApi.data) {
      profileApi.execute();
    } else if (!isLoggedIn) {
      userProfileStoreDispatch({
        type: 'updateUser',
        user: null
      });
      userProfileStoreDispatch({
        type: 'updateAccountType',
        accountType: null
      });
      userProfileStoreDispatch({
        type: 'updateIndividual',
        individual: null
      });
      userProfileStoreDispatch({
        type: 'updateMembership',
        membership: null
      });
    }
  }, [isLoggedIn, profileApi.data]);

  useEffect(() => {
    if (profileApi.data) {
      userProfileStoreDispatch({
        type: 'updateUser',
        user: profileApi.data.user
      });
      userProfileStoreDispatch({
        type: 'updateAccountType',
        accountType: profileApi.data.account_type
      });
      userProfileStoreDispatch({
        type: 'updateIndividual',
        individual: profileApi.data.individual
      });
      userProfileStoreDispatch({
        type: 'updateMembership',
        membership: profileApi.data.membership
      });
    }
  }, [profileApi.data]);

  const getCurrentLocationUrl = () => {
    const urlParts = [location.pathname, location.search];
    const redirectUrl = sessionStorage.getItem(REDIRECT_URL);

    return redirectUrl || urlParts.filter(Boolean).join('');
  };

  const renderComponent = () => {
    if (routeType === 'eula' || routeType === 'missing') {
      return cloneElement(children);
    }

    if (profile.user !== undefined && profile.user !== null) {
      if (!profile.user?.accepted_agreement && ['private', 'protected'].includes(routeType)) {
        return <Navigate replace={true} to="/eula" state={{redirectUrl: getCurrentLocationUrl()}} />;
      }
    }

    if (routeType === 'public') {
      return cloneElement(children);
    } else if (routeType === 'private') {
      if (isLoggedIn) {
        return cloneElement(children);
      } else {
        sessionStorage.setItem(REDIRECT_URL, getCurrentLocationUrl());
        return <Navigate replace={true} to="/login" />;
      }
    } else if (routeType === 'protected') {
      if (isLoggedIn && profile.user && profile.user.is_admin) {
        return cloneElement(children);
      } else if (isLoggedIn && (!profile || !profile.is_admin)) {
        return <Navigate replace={true} to="/" />;
      } else {
        sessionStorage.setItem(REDIRECT_URL, getCurrentLocationUrl());
        return <Navigate replace={true} to="/login" />;
      }
    }
  };

  return <>{renderComponent()}</>;
};
