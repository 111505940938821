import {ReactComponent as CollapseIcon} from 'assets/icons/icon_collapse.svg';
import {ReactComponent as ExtendIcon} from 'assets/icons/icon_extend.svg';
import {ReactComponent as IUUListedIcon} from 'assets/icons/icon_iuu_status.svg';
import {ReactComponent as WarningIcon} from 'assets/icons/icon_warning_none.svg';
import cn from 'classnames';
import {useMemo} from 'react';
import {renderToString} from 'react-dom/server';

import {TOGGLE_DESCENDANTS} from '../constants';
import {NodeIcons} from '../cytoscapeStyles';
import styles from './NodeLabel.module.css';

const NodeLabel = ({graph, label, node, bgColor, className}) => {
  const style = useMemo(() => {
    let styles = {background: bgColor};
    if (label.length > 10) {
      styles = {
        ...styles,
        '--node-tooltip-text': `"${label}"`,
        '--node-tooltip-color': '#ffffff',
        '--node-tooltip-background': bgColor
      };
    }

    return styles;
  }, [label]);

  const visibleLabel = useMemo(() => {
    if (label.length > 10) {
      return `${label.slice(0, 10)}...`;
    }
    return label;
  }, [label]);

  const infoIcon = useMemo(() => {
    const listed = node?.iuu_status === 'listed';
    const active_sanctions = node?.sanction_status === 'active_sanctions';

    if (listed) {
      return <IUUListedIcon className={styles.labelIcon} style={{fill: bgColor, borderColor: bgColor}} />;
    } else if (active_sanctions) {
      return <WarningIcon className={styles.labelIcon} style={{fill: bgColor, borderColor: bgColor}} />;
    }

    return null;
  }, [node]);

  const nodeId = node?.triton_id;
  const onClickString = (eventName, entityType) => {
    return `
      event.stopPropagation();
      event.preventDefault();
      document.dispatchEvent(new CustomEvent('${eventName}',{detail:{id:'${nodeId}',entityType:'${entityType}'}}))
    `;
  };

  const grouped = {};
  graph.descendants.forEach((item) => {
    if (!grouped[item.data.entityType]) {
      grouped[item.data.entityType] = [];
    }

    grouped[item.data.entityType].push(item);
  });

  const getButtonCode = (entityType, descendants) => {
    const hidden = descendants.filter((item) => item.hidden).length;

    return `
      <button
      style="background: ${NodeIcons[entityType].bgColor}"
        class="${styles.button}"
        onclick="${onClickString(TOGGLE_DESCENDANTS, entityType)}"
      >
        <div class="${styles.buttonIcon}">
          ${renderToString(hidden ? <ExtendIcon /> : <CollapseIcon />)}
        </div>
        <span>${descendants.length}</span>
      </button>
  `;
  };

  const htmlContent = {
    __html: Object.entries(grouped)
      .map(([entityType, descendants]) => getButtonCode(entityType, descendants))
      .join('')
  };

  return (
    <div data-attr-id={nodeId} className={cn(styles.container, 'nodelabel')}>
      <div data-attr-type="node" style={{backgroundColor: bgColor}} className={styles.nodeImageContainer}>
        <img
          data-attr-type="node"
          className={styles.nodeImage}
          src={NodeIcons[node.entity_type].whiteImage}
          alt={node.entity_type}
        />
      </div>
      <div className={styles.labelWrapper}>
        <p style={style} title={label} className={cn(styles.label, className)}>
          {visibleLabel}
        </p>
        {infoIcon}
      </div>
      {Object.keys(grouped).length > 0 && (
        <>
          {/* eslint-disable-next-line react/no-danger */}
          <div className={styles.buttonsContainer} dangerouslySetInnerHTML={htmlContent} />
        </>
      )}
    </div>
  );
};

export const renderNodeLabel = ({descendants}, label, node, bgColor, className) => {
  return renderToString(<NodeLabel graph={{descendants}} label={label} node={node} bgColor={bgColor} className={className} />);
};
