import styled from '@emotion/styled';
import {ArrowForwardIosOutlined} from '@mui/icons-material';
import {Divider, List, ListItem} from '@mui/material';
import Box from '@mui/material/Box';
import Icon from '@mui/material/Icon';
import cn from 'classnames';
import {renderToString} from 'react-dom/server';

import {GraphPopup} from './components/Popup';
import popupStyles from './components/Popup/Popup.module.css';
import styles from './EdgePopover.module.css';

const StyledIcon = styled(Icon)(() => ({marginRight: '0.25rem'}));

export const makePopper = (cy, ele, getNodeIcon, handleCloseTippy) => {
  let badge = ele.popper({
    content: () => {
      const div = document.createElement('div');
      div.setAttribute('data-popover-placement', 'top');
      div.className = popupStyles.popover;

      const content = renderToString(<EdgePopper ele={ele} getNodeIcon={getNodeIcon} handleCloseTippy={handleCloseTippy} />);

      div.innerHTML = content;
      div.classList.add(`canvas-popup-element-${ele._private?.data?.id.replaceAll(':', '-')}`);

      div.style.zIndex = 10;

      const cyContainer = cy.container();
      const cyCanvas = cyContainer.querySelector('canvas');

      cyCanvas.parentNode.append(div);

      return div;
    },
    popper: {
      placement: 'top',
      strategy: 'absolute'
    }
  });

  badge.setOptions({
    modifiers: [
      {
        name: 'offset',
        options: {offset: [0, 14]}
      }
    ]
  });
  let update = () => badge.update();

  cy.on('position', update);
  cy.on('scale', update);

  cy.on('pan zoom resize', update);

  ele.badge = badge;

  return ele;
};

const EdgePopper = ({ele, getNodeIcon, handleCloseTippy}) => {
  return (
    <GraphPopup
      outerChildren={
        <>
          <Divider light className={styles.dividerLine} />
          <List className={styles.listStyle}>
            {ele.data().relationships.map((relationship) => (
              <ListItem
                key={relationship}
                className={cn(styles.text, styles.relationItem)}
                onClick={() => {
                  handleCloseTippy(ele);
                }}
              >
                <span style={{fontSize: '5px', marginRight: 5}}>&#x26AA;</span>
                {' '}
                {relationship}
              </ListItem>
            ))}
          </List>
        </>
      }
    >
      <Box className={styles.iconBox}>
        <StyledIcon>
          <img src={getNodeIcon(ele.data().source)} height="20px" alt="source-icon" />
        </StyledIcon>
      </Box>
      <Box display="flex" alignItems="center" justifyContent="center" sx={{width: '32px', height: '32px', textAlign: 'center'}}>
        <ArrowForwardIosOutlined sx={{width: 13, height: 13}} />
      </Box>
      <Box sx={{width: '32px', height: '32px', textAlign: 'center'}}>
        <StyledIcon>
          <img src={getNodeIcon(ele.data().target)} height="20px" alt="target-icon" />
        </StyledIcon>
      </Box>
    </GraphPopup>
  );
};
