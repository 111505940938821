import {Box} from '@mui/material';
import {ColumnHeader} from 'components/DataTable/components/ColumnHeader/ColumnHeader';
import {DataTable} from 'components/DataTable/DataTable';
import {useMemo} from 'react';

import {SectionHeader} from '../../../components/SectionHeader/SectionHeader';
import {TableCell} from '../../../components/Table/TableCell/TableCell';

export const Users = ({users}) => {
  const rows = useMemo(() => users.map((user, index) => ({...user, id: index})), [users]);

  const columns = useMemo(
    () => [
      {
        field: 'email',
        renderHeader: () => <ColumnHeader label="Email" />,
        disableColumnMenu: true,
        minWidth: 250,
        flex: 1,
        renderCell: ({value}) => <TableCell label={value} color="secondary" />
      },
      {
        field: 'name',
        renderHeader: () => <ColumnHeader label="Name" />,
        disableColumnMenu: true,
        minWidth: 150,
        renderCell: ({row}) => {
          const nameParts = [row?.first_name, row?.last_name];

          return <TableCell label={nameParts.filter(Boolean).join(' ')} color="secondary" />;
        }
      }
    ],
    []
  );

  return (
    <Box width="100%">
      <SectionHeader line={false} unboxed={true} title="Users" />
      <Box height={240} width="100%">
        <DataTable
          columns={columns}
          rows={rows}
          autoHeight={true}
          dataGridProps={{
            getRowId: (row) => row.id,
            loading: false,
            sortingMode: 'client',
            pagination: true,
            paginationMode: 'client',
            rowCount: rows.length,
            page: 0,
            hideFooterPagination: true,
            pageSize: rows.length
          }}
          dataGridStyles={{'& .MuiDataGrid-columnHeaders': {border: 'none'}}}
          emptyOverlayProps={{text: 'There are currently no users.'}}
        />
      </Box>
    </Box>
  );
};
