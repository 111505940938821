import {HttpErrorStore} from './contexts/HttpErrorStore';
import {ProfileStore} from './contexts/Profile';
import {SupportRequestStore} from './contexts/SupportRequestStore';
import {UpdateServiceStore} from './contexts/UpdateService';

export const Stores = ({children}) => (
  <UpdateServiceStore>
    <HttpErrorStore>
      <ProfileStore>
        <SupportRequestStore>{children}</SupportRequestStore>
      </ProfileStore>
    </HttpErrorStore>
  </UpdateServiceStore>
);
