import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';

const chipStatusDct = {
  error: 'error',
  warning: 'warning',
  default: 'gray'
};

export const StatusChip = ({status, label, className, sx}) => (
  <Box display="inline-flex">
    <Chip
      label={label}
      size="small"
      color={chipStatusDct[status] || chipStatusDct.default}
      className={className}
      sx={[{fontWeight: 400}, ...(Array.isArray(sx) ? sx : [sx])]}
    />
  </Box>
);
