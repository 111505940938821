import {yupResolver} from '@hookform/resolvers/yup';
import AddIcon from '@mui/icons-material/Add';
import {Box, FormControlLabel, Grid, Radio, RadioGroup, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {CustomChipSelect} from 'components/CustomChipSelect/CustomChipSelect';
import {CustomInput} from 'components/CustomInput/CustomInput';
import {Loader} from 'components/Loader/Loader';
import {ErrorMessage} from 'components/Messages/ErrorMessage';
import {useImperativeRef} from 'hooks/useImperativeRef';
import {useEffect, useMemo, useState} from 'react';
import {Controller, useForm} from 'react-hook-form';
import {useAdminStore} from 'stores/hooks/useAdminStore';
import * as yup from 'yup';

import styles from '../../Admin.module.css';
import {Footer} from '../../components/Footer/Footer';
import {SelectOption} from '../../components/SelectOption/SelectOption';
import {SubmitBtn} from '../../components/SubmitBtn/SubmitBtn';
import {AddOrganization} from '../../Organizations/AddOrganization/AddOrganization';
import addUserStyles from './AddUser.module.css';
import {ConfirmUserDetails} from './ConfirmUserDetails/ConfirmUserDetails';

const schema = yup
  .object({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    email: yup.string().email().required(),
    organization: yup.string(),
    is_staff: yup.boolean().required()
  })
  .required();

export const SingleCreation = ({onUsers, onHideModal, onShowModal}) => {
  const theme = useTheme();
  const {organizations, users: storedUsers} = useAdminStore();
  const alreadyCreatedUsers = storedUsers.data || [];
  const [organizationSelectOpen, setOrganizationSelectOpen] = useState(false);
  const imperativeRefConfig = {
    beforeCall: onHideModal,
    afterCall: onShowModal
  };
  const [addOrganizationRef, setAddOrganizationRef] = useImperativeRef(null, imperativeRefConfig, ['open']);
  const [confirmUserDetails, setConfirmUserDetailsRef] = useImperativeRef(null, imperativeRefConfig, ['open']);
  const {
    control,
    handleSubmit,
    formState: {errors},
    watch,
    setError,
    clearErrors
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      organization: '',
      is_staff: false
    }
  });
  const currentEmail = watch('email');

  const emailAlreadyRegistered = (email) => {
    if (errors.email?.type !== undefined && errors.email?.type !== 'custom') {
      return errors.email;
    }

    const user = alreadyCreatedUsers.find((user) => user.email === email);
    const errorMessage = user ? {type: 'custom', message: 'Current email already registered.'} : undefined;

    if (!errorMessage) {
      clearErrors('email.custom');
    } else {
      setError('email', errorMessage);
    }

    return errorMessage;
  };

  useEffect(() => {
    emailAlreadyRegistered(currentEmail);
  }, [currentEmail]);

  const onSubmit = async (user) => {
    const emailCheckResult = emailAlreadyRegistered(user.email);
    if (emailCheckResult) {
      return;
    }

    let organization;
    if (user.organization) {
      organization = organizations?.data?.find((organization) => organization.name === user.organization);
      if (!organization) {
        return;
      }
    }

    const result = await confirmUserDetails.current.open([
      {
        ...user,
        organization_id: organization?.id
      }
    ]);

    if (result?.confirmed) {
      if (result?.isError) {
        const errors = result?.responseData;
        errors.forEach((error) => {
          Object.entries(error).forEach(([field, messages]) => {
            setError(field, {type: 'serverError', message: messages[0]});
          });
        });
        return;
      }
      onUsers(result?.updateUsers);
    }
  };

  const organizationOptions = useMemo(() => {
    const organizationsData = organizations.data || [];

    return organizationsData.map((organization) => organization.name);
  }, [organizations.data]);

  const openAddOrganizationModal = async () => {
    setOrganizationSelectOpen(false);
    await addOrganizationRef.current.open();
  };

  return (
    <Box className={styles.singleCreation}>
      <Loader absolute={true} loading={organizations.loading} />
      <AddOrganization ref={setAddOrganizationRef} organizations={organizations.data} />
      <ConfirmUserDetails ref={setConfirmUserDetailsRef} />
      <Grid container>
        <Grid item xs={12} className={styles.isAdminContainer}>
          <Controller
            control={control}
            rules={{required: true}}
            render={({field: {...props}}) => (
              <RadioGroup
                row
                defaultValue="user"
                value={props.value ? 'admin' : 'user'}
                onChange={(e, value) => {
                  props.onChange({target: {value: value === 'admin'}});
                }}
              >
                <FormControlLabel
                  value="user"
                  control={<Radio size="small" color="red" />}
                  label="User"
                  className={styles.radioLabel}
                />
                <FormControlLabel
                  value="admin"
                  control={<Radio size="small" color="red" />}
                  label="Administrator"
                  className={styles.radioLabel}
                />
              </RadioGroup>
            )}
            name="is_staff"
          />
          {!!errors.is_staff && <ErrorMessage message={errors.is_staff.message} />}
        </Grid>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={{required: true}}
              render={({field: {...props}}) => (
                <CustomInput label="First Name" required={true} placeholder="First Name" {...props} />
              )}
              name="first_name"
            />
            {!!errors.first_name && <ErrorMessage message={errors.first_name.message} />}
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={{required: true}}
              render={({field: {...props}}) => (
                <CustomInput label="Last Name" required={true} placeholder="Last Name" {...props} />
              )}
              name="last_name"
            />
            {!!errors.last_name && <ErrorMessage message={errors.last_name.message} />}
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={{required: true}}
              render={({field: {...props}}) => (
                <CustomInput label="Email Address" required={true} placeholder="Email Address" {...props} />
              )}
              name="email"
            />
            {!!errors.email && <ErrorMessage message={errors.email.message} />}
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              control={control}
              rules={{required: true}}
              render={({field: {...props}}) => (
                <CustomChipSelect
                  renderLabel={(option) => <SelectOption label={option} />}
                  prefix={
                    <SelectOption
                      className={addUserStyles.createOrganizationBtn}
                      optionOnClick={openAddOrganizationModal}
                      style={{borderColor: theme.palette.gray.gray100}}
                      label={
                        <>
                          <AddIcon className={addUserStyles.createOrganizationIcon} />
                          <Typography color="secondary" className={addUserStyles.createOrganizationText}>
                            Create new organization
                          </Typography>
                        </>
                      }
                    />
                  }
                  chipClassName={addUserStyles.organizationChip}
                  open={organizationSelectOpen}
                  onOpenState={setOrganizationSelectOpen}
                  closeAfterOptionClick={true}
                  onChange={(options) => {
                    props.onChange({target: {value: options[0]}});
                  }}
                  label="Organizations"
                  placeholder="Select Organizations..."
                  options={organizationOptions}
                  maxOptions={1}
                  value={props.value ? [props.value] : []}
                />
              )}
              name="organization"
            />
            {!!errors.organization && <ErrorMessage message={errors.organization.message} />}
          </Grid>
        </Grid>
      </Grid>
      <Footer display="flex" justifyContent="flex-end">
        <SubmitBtn
          onClick={async () => {
            await handleSubmit(onSubmit)();
            emailAlreadyRegistered(currentEmail);
          }}
          label="Upload"
        />
      </Footer>
    </Box>
  );
};
