import {Box, Card, CardContent, Container, Stack, Typography} from '@mui/material';
import backgroundImage from 'assets/backgrounds/background_missing.png';
import {Background} from 'components/Background/Background';

export const NetworkGraphNotFound = () => (
  <Background backgroundImage={backgroundImage} sx={{backgroundPosition: '100% 0%'}}>
    <Container maxWidth="sm" sx={{paddingTop: '10rem'}}>
      <Stack spacing={10}>
        <Box>
          <Typography variant="h2" align="center" color="white.main" sx={{fontWeight: 600}}>
            Oops
          </Typography>
        </Box>
        <Card raised elevation={2}>
          <CardContent
            sx={{
              paddingBottom: '3.5rem !important',
              paddingLeft: '3.5rem',
              paddingRight: '3.5rem',
              paddingTop: '3.5rem'
            }}
          >
            <Stack spacing={10}>
              <Typography variant="h5" sx={{fontWeight: 600}}>
                Network graph not found
              </Typography>
              <Typography align="center">The Network graph you&apos;re looking for doesn&apos;t exist.</Typography>
            </Stack>
          </CardContent>
        </Card>
      </Stack>
    </Container>
  </Background>
);
