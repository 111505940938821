import {Box} from '@mui/material';
import {ColumnHeader} from 'components/DataTable/components/ColumnHeader/ColumnHeader';
import {DataTable} from 'components/DataTable/DataTable';
import {useMemo} from 'react';

import {SectionHeader} from '../../components/SectionHeader/SectionHeader';
import {TableCell} from '../../components/Table/TableCell/TableCell';

export const MultipleOrganizationPreview = ({organizations}) => {
  // eslint-disable-next-line max-len
  const rows = useMemo(
    () =>
      organizations.map((organization, index) => ({
        ...organization,
        id: index
      })),
    [organizations]
  );

  const columns = useMemo(
    () => [
      {
        field: 'name',
        renderHeader: () => <ColumnHeader label="Organization Name" />,
        disableColumnMenu: true,
        minWidth: 150,
        flex: 1,
        renderCell: ({value}) => <TableCell label={value || ''} color="secondary" />
      }
    ],
    []
  );

  return (
    <Box width="100%">
      <SectionHeader line={false} unboxed={true} title="Organizations" />
      <Box height={240} width="100%">
        <DataTable
          columns={columns}
          rows={rows}
          autoHeight={true}
          dataGridProps={{
            getRowId: (row) => row.id,
            loading: false,
            sortingMode: 'client',
            pagination: true,
            paginationMode: 'client',
            rowCount: rows.length,
            page: 0,
            hideFooterPagination: true,
            pageSize: rows.length
          }}
          dataGridStyles={{'& .MuiDataGrid-columnHeaders': {border: 'none'}}}
          emptyOverlayProps={{text: 'There are currently no organizations.'}}
        />
      </Box>
    </Box>
  );
};
