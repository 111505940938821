import {useAuth0} from '@auth0/auth0-react';
import {Box, Typography} from '@mui/material';
import {logout, setTokens} from 'api/auth';
import {usersService} from 'api/services/users';
import {Loader} from 'components/Loader/Loader';
import {ACCESS_TOKEN_KEY} from 'constants/localStorage';
import {REDIRECT_URL, USER_NOT_FOUND} from 'constants/sessionStorage';
import {useApi} from 'hooks/useApi';
import {enqueueSnackbar} from 'notistack';
import {useEffect} from 'react';
import {useLocation} from 'react-router';
import {useNavigate} from 'react-router-dom';
import {useProfileStore} from 'stores/hooks/useProfileStore';

import styles from './CallbackPage.module.css';

const updateUserProfile = (result, dispatch) => {
  dispatch({
    type: 'updateUser',
    user: result.user
  });
  dispatch({
    type: 'updateAccountType',
    accountType: result.account_type
  });
  dispatch({
    type: 'updateIndividual',
    individual: result.individual
  });
  dispatch({
    type: 'updateMembership',
    membership: result.membership
  });
};

export const CallbackPage = () => {
  const {isAuthenticated, getAccessTokenSilently, handleRedirectCallback, logout: authLogout} = useAuth0();
  const [profile, userProfileStoreDispatch] = useProfileStore();
  const navigate = useNavigate();
  const location = useLocation();

  const getLocationRedirectUrl = () => {
    const url = sessionStorage.getItem(REDIRECT_URL) || '/';
    sessionStorage.removeItem(REDIRECT_URL);
    return url;
  };

  const profileApi = useApi({
    service: usersService.profile,
    immediate: false
  });
  const agreementApi = useApi({
    service: usersService.checkAgreement,
    immediate: false
  });
  const trackLoginApi = useApi({
    service: usersService.trackLogin,
    immediate: false
  });

  useEffect(() => {
    if (isAuthenticated && profile?.user) {
      navigate(getLocationRedirectUrl());
      return;
    }

    const getUserMetadata = async () => {
      try {
        await handleRedirectCallback();
        const accessToken = await getAccessTokenSilently();
        setTokens({[ACCESS_TOKEN_KEY]: accessToken});
        return true;
      } catch (e) {
        return false;
      }
    };

    getUserMetadata().then(async (result) => {
      if (!result) {
        sessionStorage.setItem(USER_NOT_FOUND, 'true');
        logout();
        await authLogout({logoutParams: {returnTo: `${window.location.origin}/login`}});
        return;
      }

      try {
        const data = await profileApi.execute();
        updateUserProfile(data, userProfileStoreDispatch);
        trackLoginApi.execute();
        const agreementAccepted = await agreementApi.execute();
        if (agreementAccepted?.accept_agreement) {
          navigate(getLocationRedirectUrl());
        } else {
          navigate('/eula', {state: {redirectUrl: getLocationRedirectUrl()}});
        }
      } catch {
        enqueueSnackbar('Authentication failed please try again click.', {
          key: 'sign-in-error',
          variant: 'error',
          autoHideDuration: 2500
        });
        navigate('/login', {state: location.state});
      }
    });
  }, []);

  return (
    <Box display="flex" alignItems="center" justifyContent="center" width="100vh" height="100vh">
      <Loader
        absolute={true}
        loading={true}
        title={
          <Typography color="secondary" className={styles.callbackTitle}>
            Loading Triton...
          </Typography>
        }
      />
    </Box>
  );
};
