import {Box, Typography} from '@mui/material';
import {useMemo} from 'react';

import {Line} from '../Line/Line';
import styles from './SectionHeader.module.css';

const WrapperBox = ({children}) => (
  <Box display="flex" alignItems="center" className={styles.sectionHeaderBlock}>
    {children}
  </Box>
);

export const SectionHeader = ({title, unboxed = false, line = true}) => {
  const Wrapper = useMemo(() => (unboxed ? ({children}) => children : WrapperBox), [unboxed]);

  return (
    <Wrapper>
      <Typography color="black" className={styles.sectionHeader}>
        {title}
      </Typography>
      {!!line && <Line />}
    </Wrapper>
  );
};
