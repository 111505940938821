import {Grid, Typography} from '@mui/material';

import styles from './PreviewSection.module.css';

export const PreviewSection = ({label, value}) => (
  <Grid item xs={12} className={styles.section}>
    <Typography className={styles.label}>{label}</Typography>
    <Typography className={styles.value}>{value}</Typography>
  </Grid>
);
