import {Box, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {ReactComponent as NetworkGraphBackground} from 'assets/backgrounds/background_net_graphic.svg';
import {useEffect, useState} from 'react';
import {useLayoutStore} from 'stores/hooks/useLayoutStore';

import {methodologySections} from './MethodologyContent';
import styles from './MethodologyPage.module.css';
import {MethodologySection} from './MethodologySection/MethodologySection';

export const MethodologyPage = () => {
  const {setLayoutClassName} = useLayoutStore();
  const [selectedSection, setSelectedSection] = useState();
  const theme = useTheme();

  useEffect(() => {
    setLayoutClassName(styles.methodologyPageLayout);

    return () => {
      setLayoutClassName(undefined);
    };
  }, []);

  const onChange = (key) => {
    return (e, expanded) => {
      setSelectedSection(expanded ? key : undefined);
    };
  };

  return (
    <Box className={styles.container} style={{background: theme.palette.secondary.main}}>
      <NetworkGraphBackground className={styles.networkGraphBackground} />
      <Box className={styles.innerContainer} maxWidth={1200}>
        <Typography className={styles.title} style={{color: theme.palette.white.main}}>
          TRITON METHODOLOGY
        </Typography>
        {methodologySections.map(({title, description, key}) => (
          <MethodologySection
            key={key}
            title={title}
            description={description}
            onChange={onChange(key)}
            expanded={selectedSection === key}
          />
        ))}
      </Box>
    </Box>
  );
};
