import 'index.css';

import {CssBaseline} from '@mui/material';
import * as Sentry from '@sentry/react';
import {BrowserTracing} from '@sentry/tracing';
import {App} from 'App';
import posthog from 'posthog-js';
import {PostHogProvider} from 'posthog-js/react';
import React from 'react';
import ReactDOM from 'react-dom';

Sentry.init({
  dsn: 'https://de104a4543b6425dae3fb333ce431998@o295592' + '.ingest.sentry.io/4504243041402880',
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0
});

posthog.init(process.env.REACT_APP_PUBLIC_POSTHOG_KEY, {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  person_profiles: 'identified_only',
  autocapture: false
});

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline />
    <PostHogProvider client={posthog}>
      <App />
    </PostHogProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
