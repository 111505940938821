import {useAuth0} from '@auth0/auth0-react';
import Logout from '@mui/icons-material/Logout';
import {Box, Button, Divider, Menu, MenuItem} from '@mui/material/';
import {useTheme} from '@mui/material/styles';
import {logout} from 'api/auth';
import {ReactComponent as ProfileIcon} from 'assets/icons/icon_profile.svg';
import {useResizeEvent} from 'hooks/useResizeEvent';
import {useEffect, useRef, useState} from 'react';
import {useNavigate} from 'react-router';

import styles from './AccountButton.module.css';

export const UserAccountButton = ({itemOnClick}) => {
  const {logout: authLogout} = useAuth0();
  const [menuAnchor, setMenuAnchor] = useState(null);
  const menuRef = useRef(null);
  const navigate = useNavigate();
  const resize = useResizeEvent();
  const theme = useTheme();

  useEffect(() => {
    setMenuAnchor(null);
  }, [resize.event]);

  const logOut = async () => {
    logout(false);
    await authLogout({logoutParams: {returnTo: `${window.location.origin}/login`}});
  };

  const profileOnClick = () => {
    navigate('/profile');
    setMenuAnchor(null);
    itemOnClick && itemOnClick();
  };

  const logoutOnClick = () => {
    logOut();
    setMenuAnchor(null);
    itemOnClick && itemOnClick();
  };

  return (
    <Box>
      <Box ref={menuRef} className={styles.button} onClick={() => setMenuAnchor(menuRef.current)}>
        <Box className={styles.buttonIconContainer}>
          <ProfileIcon style={{fill: theme.palette.white.main}} className={styles.buttonUserIcon} />
        </Box>
      </Box>
      <Menu anchorEl={menuAnchor} open={Boolean(menuAnchor)} onClose={() => setMenuAnchor(null)}>
        <MenuItem onClick={profileOnClick}>
          <ProfileIcon style={{fill: theme.palette.secondary.main}} className={styles.profileIcon} />
          <Box sx={{marginLeft: '0.5rem'}}>My Account</Box>
        </MenuItem>
        <Box sx={{padding: '0.5rem'}}>
          <Divider />
        </Box>
        <MenuItem onClick={logoutOnClick}>
          <Logout />
          <Box sx={{marginLeft: '0.5rem'}}>Logout</Box>
        </MenuItem>
      </Menu>
    </Box>
  );
};

export const DefaultAccountButton = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  return (
    <Button
      variant="outlined"
      edge="start"
      startIcon={<ProfileIcon style={{fill: theme.palette.white.main}} className={styles.profileIcon} />}
      color="white"
      onClick={() => navigate('/login')}
      sx={{
        marginLeft: '0.5rem',
        marginRight: '0.5rem'
      }}
    >
      Login
    </Button>
  );
};
