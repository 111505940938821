import {Box, FormControlLabel, Radio, RadioGroup} from '@mui/material';

import {SectionHeader} from '../SectionHeader/SectionHeader';
import styles from './CreationType.module.css';

export const CreationType = ({value, onChange, options}) => (
  // options = [{value, label}]
  <Box className={styles.creationType}>
    <SectionHeader title="Creation Type" />
    <RadioGroup row value={value} onChange={onChange}>
      {options.map(({value, label}) => (
        <FormControlLabel
          key={value}
          value={value}
          control={<Radio size="small" color="red" />}
          label={label}
          className={styles.radioLabel}
        />
      ))}
    </RadioGroup>
  </Box>
);
