import {organizationServices} from 'api/services/organizations';
import {usersService} from 'api/services/users';
import {useApi} from 'hooks/useApi';
import {createContext} from 'react';

const initialState = {
  users: {},
  organizations: {}
};

export const AdminStoreContext = createContext(initialState);

export const AdminStoreWrapper = ({children}) => {
  const users = useApi({
    service: usersService.adminUsers,
    immediate: true
  });
  const organizations = useApi({
    service: organizationServices.organizations,
    immediate: true
  });

  return (
    <AdminStoreContext.Provider
      value={{
        users,
        organizations
      }}
    >
      {children}
    </AdminStoreContext.Provider>
  );
};
