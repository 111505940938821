import {useEffect, useRef} from 'react';

import {useNetworkGraphContext} from '../../../../../stores/hooks/useNetworkGraphContext';
import {TOGGLE_DESCENDANTS} from '../constants';
import {NodeIcons} from '../cytoscapeStyles';
import styles from '../NetworksGraph.module.css';
import {renderNodeLabel} from '../NodeLabel/NodeLabel';

const nodeColors = {...NodeIcons};

const getInitialsNodesOrder = (nodes) => {
  const result = {};
  nodes.forEach((node, index) => {
    result[node.data.id] = index;
  });
  return result;
};

export const NodesLayer = ({networkGraphManager, nodes, onClickNode}) => {
  const {cyto} = useNetworkGraphContext();
  const nodesOrder = useRef(getInitialsNodesOrder(nodes ?? []));

  const getHiddenNodesIds = () => {
    return cyto
      .nodes()
      .filter((element) => element.hidden())
      .map((element) => element.data().id);
  };

  const getNodesVisibilityState = (nodeIds) => {
    const nodes = cyto.nodes().filter((element) => nodeIds.includes(element.data().id));

    return nodes.map((node) => {
      return {
        hidden: node.hidden(),
        id: node.data().id,
        data: node.data()
      };
    });
  };

  const nodeHtmlLabelConfig = [
    [
      {
        query: 'node',
        valign: 'bottom',
        halign: 'center',
        valignBox: 'bottom',
        halignBox: 'center',
        cssClass: styles.nodeLabelContainer,
        tpl: (data) => {
          const hiddenNodesIds = getHiddenNodesIds();
          const node = networkGraphManager.getNodesEntitiesData().find((entity) => entity.triton_id === data.id);

          return hiddenNodesIds.includes(data.id)
            ? ''
            : renderNodeLabel(
              {descendants: getNodesVisibilityState(networkGraphManager.descendants(data.id).map(({id}) => id))},
              data.label,
              node,
              nodeColors?.[data.entityType]?.bgColor ?? '#000',
              `${styles.text} ${styles.nodeTitle}`
            );
        }
      }
    ],
    {enablePointerEvents: true}
  ];

  const bindListeners = () => {
    if (!cyto) {
      return;
    }

    let draggingNode;
    cyto.on('drag', 'node', (event) => {
      const nodeId = event.target.data().id;
      if (draggingNode === nodeId) {
        return;
      }
      draggingNode = nodeId;
      const currentNodeZIndex = nodesOrder.current[nodeId];
      const max = Math.max(...Object.values(nodesOrder.current));

      Object.entries(nodesOrder.current)
        .filter(([, zIndex]) => zIndex > currentNodeZIndex)
        .forEach(([key, zIndex]) => {
          nodesOrder.current[key] = zIndex - 1;
        });
      nodesOrder.current[nodeId] = max;

      Object.entries(nodesOrder.current).forEach(([id, zIndex]) => {
        cyto.nodes(`[id='${id}']`).css('z-index', zIndex);
      });

      const container = cyto.container();
      container?.querySelectorAll(`.${styles.nodeLabelContainer}`).forEach((el) => {
        const id = el.querySelector('.nodelabel')?.getAttribute('data-attr-id');
        el.style.zIndex = nodesOrder.current[id];
      });
    });

    cyto.on('mouseover', 'node', () => {
      document.body.style.cursor = 'pointer';
      document.documentElement.style.cursor = 'pointer';
    });
    cyto.on('mouseout', 'node', function () {
      document.body.style.cursor = 'default';
      document.documentElement.style.cursor = 'default';
    });

    cyto.bind('click', 'node', function (event) {
      if (event.originalEvent.target.getAttribute('data-attr-type') !== 'node') {
        return;
      }

      onClickNode(event.target.id());
    });

    return () => {
      cyto.removeListener('drag', 'node');
      cyto.removeListener('mouseover', 'node');
      cyto.removeListener('mouseout', 'node');
      cyto.removeListener('click', 'node.l1');
    };
  };

  const attachNodeLabels = () => {
    if (cyto) {
      cyto.container()?.children[0]?.querySelector('div')?.remove();
      cyto.nodeHtmlLabel(...nodeHtmlLabelConfig);
    }
  };

  const toggleDescendants = (e) => {
    const nodeId = e.detail.id;
    const entityType = e.detail.entityType;
    if (!nodeId) {
      return;
    }

    const graphNode = networkGraphManager.getNode(nodeId);
    let allDescendants = networkGraphManager.descendants(nodeId).filter((node) => node.nodeData.entityType === entityType);

    const allDescendantsIds = allDescendants.map(({id}) => id);
    const allDescendantsElements = cyto.nodes().filter((el) => allDescendantsIds.includes(el.data().id));

    const hiddenDescendants = allDescendantsElements.filter((el) => el.hidden());

    const show = !!hiddenDescendants.length;
    if (show) {
      const shouldShowIds = allDescendants
        .flatMap((descendant) => networkGraphManager.getPathsToAncestor(descendant, graphNode))
        .filter((id) => id !== nodeId);

      cyto
        .nodes()
        .filter((el) => shouldShowIds.includes(el.data().id))
        .forEach((el) => el.show());
    } else {
      allDescendants = allDescendants.flatMap((node) => {
        return [node, ...networkGraphManager.descendants(node.id)];
      });
      const allDescendantsIds = allDescendants.map(({id}) => id);
      const allDescendantsElements = cyto.nodes().filter((el) => allDescendantsIds.includes(el.data().id));

      allDescendantsElements.forEach((el) => el.hide());
    }
    cyto.container()?.children[0]?.querySelector('div')?.remove();
    cyto.nodeHtmlLabel(...nodeHtmlLabelConfig);
  };

  useEffect(() => {
    document.addEventListener(TOGGLE_DESCENDANTS, toggleDescendants);

    return () => {
      document.removeEventListener(TOGGLE_DESCENDANTS, toggleDescendants);
    };
  }, [cyto]);

  useEffect(() => attachNodeLabels(), [cyto]);

  useEffect(() => bindListeners(), [cyto]);

  return null;
};
