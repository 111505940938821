import CloseIcon from '@mui/icons-material/Close';
import {Box, IconButton, Typography} from '@mui/material';
import cn from 'classnames';

import {Line} from '../Line/Line';
import styles from './ModalHeader.module.css';

export const ModalHeader = ({onClose, title, hideLine, absolute, className}) => {
  const boxStyles = absolute
    ? {
      position: 'absolute',
      top: 0
    }
    : {};

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent={title ? 'space-between' : 'flex-end'}
        className={cn(styles.header, className)}
        style={boxStyles}
      >
        {!!title && (
          <Typography color="black" className={styles.headerTitle}>
            {title}
          </Typography>
        )}
        <IconButton onClick={onClose} className={styles.closeButton}>
          <CloseIcon className={styles.closeButtonIcon} color="secondary" />
        </IconButton>
      </Box>
      {!hideLine && <Line />}
    </>
  );
};
