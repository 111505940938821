import {Box, Typography} from '@mui/material';
import {useTheme} from '@mui/material/styles';
import {ReactComponent as NetworkGraphIcon} from 'assets/icons/icon_network_graph_table.svg';
import cn from 'classnames';
import {DataTable} from 'components/DataTable/DataTable';
import {EntityType} from 'components/EntityType/EntityType';
import {FavoriteButton} from 'components/FavoriteButton/FavoriteButton';
import {IUUStatus} from 'components/IUUStatus/IUUStatus';
import {SanctionStatus} from 'components/SanctionStatus/SanctionStatus';
import {ViewNetwork} from 'components/ViewNetwork/ViewNetwork';
import {hasFlag} from 'country-flag-icons';
import {getCountryNameByCountryCode} from 'utils/helpers';

import {ColumnHeader} from './ColumnHeader/ColumnHeader';
import {Pagination} from './Pagination';
import styles from './SearchResultsTable.module.css';

export const SearchResultsTable = ({
  rows,
  loading,
  onClickCell,
  viewNetworksHandleClick,
  // onFavorite - (is_favorite, triton_id) => void
  onFavorite,
  // pagination - {count, page, pageSize}
  pagination,
  onPage,
  onPageSize,
  onSort,
  dataGridProps,
  className
}) => {
  const theme = useTheme();

  const renderSanctionStatusCell = (row) => (
    <SanctionStatus iconMode={true} sanction_status={row.sanction_status} entity_type={row.entity_type} />
  );

  const renderIUUStatusCell = (row) => <IUUStatus iconMode={true} iuuStatus={row?.iuu_status} entity_type={row.entity_type} />;

  const renderFlag = (countries) => {
    const countryCode = countries?.[0];
    const countryName = getCountryNameByCountryCode(countryCode) || 'N/A';

    return (
      <div className={styles.flagNationalityContainer}>
        {hasFlag(countryCode) && (
          <img
            className={styles.countryFlag}
            alt={countryName}
            src={`http://purecatamphetamine.github.io/country-flag-icons/3x2/${countryCode}.svg`}
          />
        )}
        <Typography title={countryName} className={styles.countryName} variant="subtitle2" color="gray.gray900">
          {countryName}
        </Typography>
      </div>
    );
  };

  const columns = [
    {
      field: 'label',
      disableColumnMenu: true,
      minWidth: 320,
      flex: 1,
      renderHeader: () => <ColumnHeader label="Name" className={styles.labelColumn} />,
      renderCell: ({value, row}) => (
        <Box width="100%" display="flex" alignItems="center">
          <FavoriteButton
            tritonId={row.triton_id}
            isFavorite={row.is_favorite}
            onUpdateFavorite={(is_favorite) => {
              onFavorite(is_favorite, row.triton_id);
            }}
          />
          <div className={styles.labelContainer}>
            <span title={value} className={styles.label}>
              {value}
            </span>
            {renderIUUStatusCell(row)}
            {renderSanctionStatusCell(row)}
          </div>
        </Box>
      )
    },
    {
      field: 'entity_type',
      disableColumnMenu: true,
      minWidth: 150,
      renderHeader: () => <ColumnHeader label="Entity Type" />,
      renderCell: ({value}) => <EntityType entityType={value} />
    },
    {
      field: 'countries',
      disableColumnMenu: true,
      minWidth: 180,
      renderHeader: () => <ColumnHeader label="Flag/Nationality" />,
      renderCell: ({value}) => renderFlag(value)
    },
    {
      field: 'imo_id',
      type: 'number',
      align: 'left',
      headerAlign: 'left',
      disableColumnMenu: true,
      minWidth: 100,
      sortable: false,
      renderHeader: () => <ColumnHeader label="IMO" />,
      valueGetter: ({value}) => (value === '-1' ? '-' : value || '-')
    },
    {
      field: 'network',
      disableColumnMenu: true,
      minWidth: 200,
      sortable: false,
      align: 'center',
      renderHeader: () => <ColumnHeader label="Network Graph" endAddon={<NetworkGraphIcon />} justifyContent="center" />,
      valueGetter: ({row}) => row.triton_id,
      renderCell: ({value}) => (
        <ViewNetwork
          componentType="link"
          tritonId={value}
          handleClick={() => {
            viewNetworksHandleClick(value);
          }}
        />
      )
    }
  ];

  const handleCellClick = (field, id) => {
    if (field !== 'is_favorite') {
      onClickCell(id);
    }
  };

  return (
    <>
      <DataTable
        columns={columns}
        className={cn(styles.dataTable, className)}
        rows={rows}
        autoHeight={true}
        dataGridProps={{
          getRowId: (row) => row.triton_id,
          loading: loading,
          sortingMode: 'server',
          onSortModelChange: (sortModel) => {
            onSort && onSort(sortModel[0]);
          },
          pagination: true,
          paginationMode: 'server',
          rowCount: pagination.count,
          page: pagination.page,
          hideFooterPagination: true,
          pageSize: pagination.pageSize,
          onCellClick: ({field, id}) => handleCellClick(field, id),
          ...dataGridProps
        }}
        dataGridStyles={{
          '& .MuiDataGrid-columnHeaders': {border: 'none'},
          '& .MuiDataGrid-row': {borderBottom: `1px solid ${theme.palette.gray.gray100}`},
          '& .MuiDataGrid-footerContainer': {borderTop: 'none'}
        }}
        emptyOverlayProps={{text: 'There are currently no search results.'}}
      />
      <Pagination pagination={pagination} onPageSize={onPageSize} onPage={(page) => onPage(page - 1)} />
    </>
  );
};
